import React from 'react';
import { twMerge } from 'tailwind-merge';

import { contentTypes } from '../../../../../common/Constants';
import { AudioSelectedSearchFilterOptions } from '../../../containers/MenuContainerInterfaces';
import { vocalTypeRenamed as vocalTypeOptions } from '../../../entities/AudioSearchFilterOptions';
import { AudioFilterGroupNames } from '../../menu/common/MenuEnums';
import RadioGroup from '../../menu/common/RadioGroup';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const convertSelectedOptionsToText = (selectedOption, allOptions) => {
  const selectedLabels = allOptions
    .filter((option) => {
      return option.value === selectedOption;
    })
    .map((option) => {
      return option.label;
    });
  return selectedLabels.length > 0 ? selectedLabels[0] : '';
};

interface MobileVocalsFilterProps {
  onChange: (name: string, value: string | number) => void;
  selectedSearchFilterOptions: AudioSelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
  className?: string;
}

const MobileVocalsFilter = (props: MobileVocalsFilterProps): JSX.Element => {
  const {
    onChange,
    selectedSearchFilterOptions,
    collapsed,
    toggleCollapsed,
    className,
  } = props;

  const { vocalType, contentType } =
    selectedSearchFilterOptions as AudioSelectedSearchFilterOptions;

  const vocalTypeOptionsInStrings = vocalTypeOptions.map(({ label, value }) => {
    return { label, value: String(value) };
  });

  const invalidSelectionMessage =
    contentType !== contentTypes.MUSIC && 'Select Music to see Vocals';

  return (
    <div className={twMerge('flex flex-col', className)}>
      <MobileCollapsibleFilter
        name="Vocals"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSelectedOptionsToText(
          vocalType,
          vocalTypeOptions
        )}
        invalidSelectionMessage={invalidSelectionMessage}
      >
        <RadioGroup
          groupName={AudioFilterGroupNames.VOCAL}
          allOptions={vocalTypeOptionsInStrings}
          checkedOption={String(vocalType)}
          onChange={onChange}
        />
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileVocalsFilter;
