import React, { useEffect, useState, useRef, PropsWithChildren } from 'react';

import './collapse.less';

type Props = {
  isCollapsed: boolean;
  controlId: string;
};

export default function Collapse({
  isCollapsed,
  children,
  controlId,
}: PropsWithChildren<Props>) {
  const [height, setHeight] = useState<number>();
  const container = useRef<HTMLDivElement>();

  useEffect(() => {
    setHeight(container.current.offsetHeight);
  }, []);

  const collapsedStyleProps = {
    maxHeight: 0,
  };

  return (
    <div className="collapseContainer" ref={container}>
      {height ? (
        <div
          className="collapseSection"
          style={isCollapsed ? collapsedStyleProps : {}}
          id={controlId}
        >
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
}
