import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import { SearchFilterContentTypes } from '@videoblocks/shared-components';
import { Button, ButtonColor, ButtonSize } from '@videoblocks/storywind';

import { setAudioFiltersChanged } from '../../../Audio/actions/AudioActions';
import { getMonthlyDownloadsRemaining } from '../../../FeatureLimits/FeatureLimitsApi';
import { updateMonthlyDownloadsRemaining } from '../../../auth/AuthActions';
import {
  selectAuthPermissions,
  selectIsLoggedIn,
  selectMonthlyDownloadsRemaining,
  selectPrimarySubscription,
  selectUseSearchHeaderMicrofrontend,
} from '../../../auth/AuthSelectors';
import { Subscription, PlanClassification } from '../../../auth/AuthTypes';
import SiteConstants, {
  SiteEnum,
} from '../../../common/SiteConstants/SiteConstants';
import Head from '../../../common/components/Head';
import SearchHeaderMicrofrontend from '../../../common/components/Microfrontends/SearchHeaderMicrofrontend';
import { getCurrentSite } from '../../../common/utils';
import {
  ADOBE_PLUGIN_LANDING_PAGE_RELATIVE_PATH,
  ADOBE_PLUGIN_MARKETPLACE_URL,
} from '../../Shared/AdobeConstants';
import { searchOrigins } from '../../Shared/constants';
import { RelatedSearch, SearchFeatures } from '../SearchTypes';
import {
  collapseMoreLikeThisDrawer,
  setIsSideMenuOpened,
  updateSearchOptionsAndRefresh,
} from '../actions/SearchActions';
import FilterButton from '../components/FilterButton';
import SortDropdown from '../components/SortDropdown';
import ResultCount from '../components/appliedFilters/ResultCount';
import MobileButtonsForFilters from '../components/buttons/MobileButtonsForFilters';
import DropdownFilter from '../components/filters/DropdownFilter';
import MobileFiltersModal from '../components/modal/MobileFiltersModal';
import MobileSortModal from '../components/modal/MobileSortModal';
import RelatedSearchContainer from '../components/relatedSearch/RelatedSearchContainer';
import VideoRelatedSearchItem from '../components/relatedSearch/VideoRelatedSearchItem';
import { ALL_VIDEOS_CONTENT_TYPE } from '../entities/SearchFilterOptions';
import {
  appleMotionTemplateTypeId,
  templatesContentTypeLabel,
  daVinciResolveTemplateTypeId,
} from '../entities/VideoSearchFilterOptions';
import {
  selectCanonicalLink,
  selectHideSearchResults,
  selectPagination,
  selectRelatedSearch,
  selectSearchContext,
  selectSearchDescription,
  selectSearchDescriptionWithoutSearchTerm,
  selectSearchFeatures,
  selectSearchFilterOptions,
  selectSearchIsLoading,
  selectSearchPageHeading,
  selectSearchTerm,
  selectSslid,
  selectSuggestionsQuery,
} from '../selectors/searchSelectors';
import { SelectedSearchFilterOptions } from './MenuContainerInterfaces';
import ProminentSearchContainer from './ProminentSearchContainer';
import SearchResultsContainer from './SearchResultsContainer';

import '../../../../common/stylesheets/modules/search/SearchContainer.less';
import '../../../../common/stylesheets/modules/search/search-side-menu.less';

type MenuContainer = ({
  closeSideMenu,
  showSideMenu,
  shouldOpenSideMenu,
}: {
  closeSideMenu: () => void;
  showSideMenu: () => void;
  shouldOpenSideMenu: boolean;
}) => React.ReactElement;

type Props = {
  // passed
  renderAppliedFilters: () => React.ReactNode;
  menuContainer: MenuContainer;

  // redux
  canonicalLink: string;
  searchDescription: string;
  searchTerm: string;
  searchDescriptionWithoutSearchTerm: string;
  hideSearchResults: boolean;
  searchIsLoading: boolean;
  selectedSearchFilterOptions: SelectedSearchFilterOptions;
  relatedSearch: RelatedSearch[];
  features: SearchFeatures;
  pageHeading: string;
  isLoggedIn: boolean;
  pproPluginEnabled: boolean;
  primarySubscription: Subscription;
  monthlyDownloadsRemaining: number;
  context: string;
  sslid: string;
  numberOfResults: number;
  selectedSuggestionsQuery: string;

  shouldUseSearchHeaderMicrofrontend: boolean;

  // actions
  updateMonthlyDownloadsRemaining: typeof updateMonthlyDownloadsRemaining;
  setIsSideMenuOpened: typeof setIsSideMenuOpened;
  setAudioFiltersChanged: typeof setAudioFiltersChanged;
  updateSearchOptionsAndRefresh: typeof updateSearchOptionsAndRefresh;
  collapseMoreLikeThisDrawer: typeof collapseMoreLikeThisDrawer;

  calculateAppliedFilterNums: (
    selectedSearchFilterOptions: SelectedSearchFilterOptions
  ) => number;
};

type State = {
  shouldOpenSideMenu: boolean;
  errorMessage: string;
  shouldShowPProPluginDialog: boolean;
};

class SearchContainer extends Component<Props, State> {
  constructor(props) {
    let showPProDialog = false; // don't default to true because react reconciliation will do weird things
    if (window.localStorage) {
      showPProDialog = localStorage.getItem('showPProPluginDialog') === null;
    }
    super(props);
    this.state = {
      shouldOpenSideMenu:
        !this.props.features.isMobile &&
        !(
          this.props.features.useRemainingSearchUI &&
          this.props.selectedSearchFilterOptions.contentClass === 'video'
        ),
      errorMessage: '',
      shouldShowPProPluginDialog: showPProDialog,
    };

    // for hotjar exit survey experiment
    const myWindow = window as any;
    if (this.props.isLoggedIn) {
      myWindow.hj = () => {};
    }
  }

  async componentDidMount() {
    /**
     * Because the Search page is server-side rendered, the browser will cache the
     * previous version of the javascript bundle, including the redux state (ex. 10 downloads remaining).
     * This call specifically addresses the edge-case where a user:
     *  - downloads something on the Search page (i.e. now 9 downloads remaining)
     *  - navigates away to another page
     *  - and then clicks the browser back button to come back to the Search page.
     *
     * It will update the "downloads remaining" counter that is shown so
     * it's not stale (i.e. '9' instead of '10')
     */
    if (
      !this.props.primarySubscription?.isMakerOnly &&
      this.props.primarySubscription?.planClassification ===
        PlanClassification.STARTER_PLAN_CLASSIFICATION
    ) {
      try {
        const monthlyDownloadsRemaining = await getMonthlyDownloadsRemaining();
        this.props.updateMonthlyDownloadsRemaining(monthlyDownloadsRemaining);
      } catch (e) {
        console.error(
          'There was an error getting monthly downloads remaining:',
          e
        );
      }
    }
  }

  closePremiereProPluginDialog() {
    this.setState({ shouldShowPProPluginDialog: false });
    if (window.localStorage) {
      localStorage.setItem('showPProPluginDialog', 'false');
    }
  }

  showSideMenu() {
    this.setState({ shouldOpenSideMenu: true });
    this.props.setIsSideMenuOpened(true);
  }

  closeSideMenu() {
    this.setState({ shouldOpenSideMenu: false });
    this.props.setIsSideMenuOpened(false);
  }
  getMenuContainer() {
    const { menuContainer: MenuContainer } = this.props;

    return (
      <>
        <div
          className={`${
            this.state.shouldOpenSideMenu &&
            this.props.features.useRemainingSearchUI
              ? 'ml-4'
              : ''
          }`}
        >
          <MenuContainer
            closeSideMenu={this.closeSideMenu.bind(this)}
            showSideMenu={this.showSideMenu.bind(this)}
            shouldOpenSideMenu={this.state.shouldOpenSideMenu}
          />
        </div>
        <MobileFiltersModal />
        <MobileSortModal />
      </>
    );
  }

  shouldHideResultCount() {
    const { searchIsLoading, selectedSearchFilterOptions } = this.props;

    if (searchIsLoading || !selectedSearchFilterOptions) {
      return true;
    } else {
      const {
        contentType,
        searchTerm,
        minDuration,
        maxDuration,
        talentReleased,
        propertyReleased,
      } = selectedSearchFilterOptions;

      return (
        contentType === ALL_VIDEOS_CONTENT_TYPE &&
        !searchTerm &&
        minDuration === SiteConstants.getInstance().getMinDuration() &&
        maxDuration === SiteConstants.getInstance().getMaxDuration() &&
        !talentReleased &&
        !propertyReleased
      );
    }
  }
  renderSearchTopBar() {
    const { selectedSearchFilterOptions } = this.props;
    const { sort, sortOrder } = selectedSearchFilterOptions;
    // reconstructs ordered sort options into a valid sort option key
    const filterValue = sortOrder ? `${sort}_${sortOrder.toLowerCase()}` : sort;
    const numFilters = this.props.calculateAppliedFilterNums(
      selectedSearchFilterOptions
    );

    return (
      <div>
        <div className="flex w-full items-center p-4">
          <div className="flex space-x-4">
            <FilterButton
              isOpen={this.state.shouldOpenSideMenu}
              closeSideMenu={this.closeSideMenu.bind(this)}
              openSideMenu={this.showSideMenu.bind(this)}
              numFilters={numFilters}
            />
            <SortDropdown
              value={filterValue}
              options={this.getSortOptionsNew()}
              onChange={(value) => {
                this.handleSortChanged('sort', value);
              }}
            />
            {this.renderResultCountsForVideo()}
          </div>
        </div>
        {this.getRelatedSearchContainer()}
      </div>
    );
  }

  renderResultCountsForVideo() {
    if (this.props.features.useRemainingSearchUI) {
      const isVideo =
        this.props.selectedSearchFilterOptions.contentClass === 'video';
      if (isVideo) {
        const { numberOfResults } = this.props;
        if (this.shouldHideResultCount()) {
          return null;
        } else {
          return (
            <ResultCount
              numberOfResults={numberOfResults}
              className={'absolute right-0 pr-4 -z-1'}
            />
          );
        }
      }
    }
  }
  renderSearchHTMLHeadElements() {
    // Usually meta data is handled on the BE,
    // but the canonical link should be handled client side in case crawlers access any client side searches.
    // In that case we want to show the correct canonical to reference in SERPs

    const { canonicalLink, numberOfResults } = this.props;
    return (
      <Head>
        {canonicalLink && <link rel="canonical" href={canonicalLink} />}
        {numberOfResults && (
          <script type="application/ld+json">
            {`{
          "@context": "https://schema.org",
          "@type": "ItemList",
          "numberOfItems": "${numberOfResults}"
        }`}
          </script>
        )}
      </Head>
    );
  }

  render() {
    const searchResultClass = this.state.shouldOpenSideMenu
      ? `open-side-menu ${getCurrentSite()}`
      : `close-side-menu ${getCurrentSite()}`;

    const remainingSearchUI = this.props.features.useRemainingSearchUI
      ? 'flex flex-col w-full px-4 pb-4'
      : 'border-right p-4';

    return (
      <>
        {this.renderSearchHTMLHeadElements()}
        <div id="search-results-app" className="SearchContainer">
          {this.renderSeoBanner()}
          {!this.props.features.useRemainingSearchUI && (
            <MobileButtonsForFilters />
          )}
          {this.props.features.useRemainingSearchUI &&
            this.renderSearchTopBar()}
          <div className="search-results-app-container">
            {this.getMenuContainer()}
            <div
              id="main-search-content"
              className={`searchContainer-results ${searchResultClass} ${remainingSearchUI}`}
            >
              {this.props.features.useRemainingSearchUI
                ? this.renderMainSearchContentControlNew()
                : this.renderMainSearchContentControl()}
            </div>
          </div>
        </div>
      </>
    );
  }

  renderAdditionalRelatedSearchTerm = () => {
    const { relatedSearch } = this.props;
    const maxNumberOfRelatedSearchTerms = 25;

    return (
      relatedSearch &&
      relatedSearch.length > maxNumberOfRelatedSearchTerms && (
        <div className="flex flex-col space-y-2 mb-12">
          <span className="font-bold text-gray-900 text-lg">
            Users also searched for:{' '}
          </span>
          <div className="flex space-x-1">
            {relatedSearch
              .slice(maxNumberOfRelatedSearchTerms)
              .map((el, index) => {
                return (
                  <div key={index}>
                    <a className="font-semibold text-sm" href={el.url}>
                      {el.relatedSearchTerm}
                    </a>
                    {index < maxNumberOfRelatedSearchTerms - 1 && (
                      <span>{', '}</span>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      )
    );
  };

  renderSeoBanner = () => {
    const {
      pageHeading,
      searchDescriptionWithoutSearchTerm,
      shouldUseSearchHeaderMicrofrontend,
    } = this.props;

    // Do not show SEO headers if logged in.
    const optionalSeoHeaders = this.props.isLoggedIn ? null : (
      <div className="text-center mx-2 lg:m-0">
        <h1 className="m-0 text-3xl font-bold">{pageHeading}</h1>
        <h2 className="text-sm font-sans font-normal mt-2">
          {searchDescriptionWithoutSearchTerm}
        </h2>
      </div>
    );

    return (
      <div className="m-auto mb-9 flex flex-col gap-y-6 max-w-3xl items-center">
        {optionalSeoHeaders}
        <div
          id="unifiedNav-search-results"
          data-testid="unifiedNav-search-results"
        >
          {shouldUseSearchHeaderMicrofrontend ? (
            <SearchHeaderMicrofrontend
              path={__CURRENT_PATH__ + __CURRENT_SEARCH_PARAMS__}
            />
          ) : (
            <ProminentSearchContainer
              showContentToggles={false}
              useNewSearchUI
            />
          )}
        </div>
      </div>
    );
  };

  renderMainSearchContentControl() {
    const { primarySubscription, selectedSearchFilterOptions } = this.props;
    const { contentType, templateType, sort, sortOrder } =
      selectedSearchFilterOptions;
    // reconstructs ordered sort options into a valid sort option key
    const filterValue = sortOrder ? `${sort}_${sortOrder.toLowerCase()}` : sort;

    return (
      <React.Fragment>
        <div id="searchContainer-adobeBanner">
          {contentType === templatesContentTypeLabel &&
            !this.props.features.isMobile &&
            this.state.shouldShowPProPluginDialog &&
            templateType !== appleMotionTemplateTypeId &&
            templateType !== daVinciResolveTemplateTypeId &&
            this.props.isLoggedIn &&
            this.renderAdobePluginDialogToMembers()}
        </div>
        {this.renderSuggestionsCorrectionText()}
        <div
          id="searchContainer-filterAndSort"
          className="searchContainer-row items-baseline justify-between pb-2 md:pb-0"
        >
          <div
            className="searchContainer-appliedFilters"
            id="searchContainer-filters"
          >
            {this.props.renderAppliedFilters()}
          </div>
          {/*The Growth condition relates to the mid-tier plan addition in the OMFG-819 AB test. This logic will require cleanup if the test is favorable.*/}
          {!this.props.primarySubscription?.isMakerOnly &&
            this.props.primarySubscription?.planClassification ===
              PlanClassification.STARTER_PLAN_CLASSIFICATION &&
            Number.isInteger(primarySubscription.monthlyDownloadsRemaining) && (
              <div className="flex-shrink-0 text-sm font-bold text-gray-800">
                {primarySubscription.monthlyDownloadsRemaining} downloads left
                this month
              </div>
            )}
          <DropdownFilter
            name="sort"
            label="Sort By:"
            value={filterValue}
            options={this.getSortOptions()}
            onChange={this.handleSortChanged}
            disabled={this.getIsDisabled()}
            classNames={[
              'searchContainer-sortBy',
              'sort-type-container',
              'ab-hoopla-announcement',
            ]}
          />
        </div>

        <div
          id="searchContainer-relatedSearchTerms"
          className="searchContainer-row relatedSearchTerms-wrapper flex-wrap relatedSearchTerms-wrapper-experiment"
        >
          {this.getRelatedSearchContainer()}
        </div>

        <SearchResultsContainer />
        {!this.shouldShowRelatedSearch() && <VideoRelatedSearchItem />}
      </React.Fragment>
    );
  }

  renderMainSearchContentControlNew() {
    const { primarySubscription, selectedSearchFilterOptions } = this.props;
    const { contentType, templateType, sort, sortOrder } =
      selectedSearchFilterOptions;
    // reconstructs ordered sort options into a valid sort option key
    const filterValue = sortOrder ? `${sort}_${sortOrder.toLowerCase()}` : sort;

    return (
      <div className="w-full flex flex-col">
        <div id="searchContainer-adobeBanner">
          {contentType === templatesContentTypeLabel &&
            !this.props.features.isMobile &&
            this.state.shouldShowPProPluginDialog &&
            templateType !== appleMotionTemplateTypeId &&
            templateType !== daVinciResolveTemplateTypeId &&
            this.props.isLoggedIn &&
            this.renderAdobePluginDialogToMembers()}
        </div>
        {this.renderSuggestionsCorrectionText()}
        <div
          className="searchContainer-appliedFilters z-5"
          id="searchContainer-filters"
        >
          {this.props.renderAppliedFilters()}
        </div>
        {!this.props.primarySubscription?.isMakerOnly &&
          this.props.primarySubscription?.planClassification ===
            PlanClassification.STARTER_PLAN_CLASSIFICATION &&
          Number.isInteger(primarySubscription.monthlyDownloadsRemaining) && (
            <div className="flex-shrink-0 text-sm font-bold text-gray-800">
              {primarySubscription.monthlyDownloadsRemaining} downloads left
              this month
            </div>
          )}
        <SearchResultsContainer />
        {!this.props.searchIsLoading &&
          this.renderAdditionalRelatedSearchTerm()}
      </div>
    );
  }

  renderAdobePluginDialogToMembers() {
    const adobeUrl = this.props.pproPluginEnabled
      ? ADOBE_PLUGIN_MARKETPLACE_URL
      : ADOBE_PLUGIN_LANDING_PAGE_RELATIVE_PATH;
    return (
      <aside className="py-3 px-4 mb-6 bg-gray-200 w-full rounded flex justify-between items-start">
        <div className="flex">
          <img
            src="/assets/common/images/home-v2/icons/ppro-circle.png"
            alt="Premiere Pro Icon"
            className="mr-3 w-9 h-9"
          />
          <img
            src="/assets/common/images/home-v2/icons/ae-circle.png"
            alt="After Effects Icon"
            className="mr-3 w-9 h-9"
          />
          <a
            target="_blank"
            href={adobeUrl}
            className="leading-5 font-semibold text-base text-black flex items-center min-h-9"
          >
            Search Storyblocks' library and download directly in your editor
            with our new plugin for Adobe Creative Cloud.
          </a>
        </div>
        <div className="flex ml-4">
          <Button
            href={adobeUrl}
            target="_blank"
            color={ButtonColor.Secondary}
            size={ButtonSize.Small}
            className="whitespace-nowrap min-h-9 flex items-center"
            onClick={() =>
              Telemetry.increment('ppro.site-hook.ppro-template-search-banner')
            }
          >
            Learn More &#8599;
          </Button>
          <div
            role="button"
            tabIndex={0}
            className="ml-4 cursor-pointer"
            onKeyDown={() => this.closePremiereProPluginDialog()}
            onClick={() => this.closePremiereProPluginDialog()}
          >
            <img
              className="min-w-6 min-h-8"
              src={`${__ASSETS_COMMON_IMAGES_URL__}/icons/sb-close-black.svg`}
              alt="Close"
              width={24}
              height={24}
            />
          </div>
        </div>
      </aside>
    );
  }

  renderPProPluginDialogToMembers() {
    return (
      <div className="py-3 px-4 mb-6 bg-gray-200 w-full rounded">
        <div className="flex justify-between items-start">
          <div className="flex">
            <img
              src="/assets/common/images/home-v2/icons/ppro-circle.png"
              alt="Premiere Pro Icon"
              className="mr-3 w-9 h-9"
            />
            <a
              target="_blank"
              href={ADOBE_PLUGIN_MARKETPLACE_URL}
              className="leading-5 font-semibold text-base text-black flex items-center min-h-9"
            >
              Search Storyblocks' library and download directly in Premiere Pro
              with our new plugin
            </a>
          </div>
          <div className="flex ml-4">
            <Button
              href={ADOBE_PLUGIN_MARKETPLACE_URL}
              target="_blank"
              color={ButtonColor.Secondary}
              size={ButtonSize.Small}
              className="whitespace-nowrap min-h-9 flex items-center"
              onClick={() =>
                Telemetry.increment(
                  'ppro.site-hook.ppro-template-search-banner'
                )
              }
            >
              Install Now &#8599;
            </Button>
            <div
              role="button"
              tabIndex={0}
              className="ml-4 cursor-pointer"
              onKeyDown={() => this.closePremiereProPluginDialog()}
              onClick={() => this.closePremiereProPluginDialog()}
            >
              <img
                className="min-w-6 min-h-8"
                src={`${__ASSETS_COMMON_IMAGES_URL__}/icons/sb-close-black.svg`}
                alt="Close"
                width={24}
                height={24}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSuggestionsCorrectionText() {
    const { searchTerm } = this.props.selectedSearchFilterOptions;
    const suggestionsQuery = this.props.selectedSuggestionsQuery;
    return (
      suggestionsQuery && (
        <div className="w-full text-sm font-normal text-gray-700 mb-3">
          Your search for <span className="font-bold">{searchTerm}</span> did
          not match any results. Showing results for{' '}
          <span className="font-bold">{suggestionsQuery}</span> instead
        </div>
      )
    );
  }

  getSortOptions() {
    const defaultOptions = {
      most_relevant: 'Most Relevant',
      most_downloaded: 'Most Downloaded',
      most_recent: 'Most Recent',
      trending_now: 'Trending Now',
      undiscovered: 'Undiscovered',
    };

    const audioOptions = {
      duration_asc: 'Duration: Low-High',
      duration_desc: 'Duration: High-Low',
      title_asc: 'Title: A-Z',
      title_desc: 'Title: Z-A',
    };

    return getCurrentSite() === SiteEnum.Audioblocks
      ? { ...defaultOptions, ...audioOptions }
      : defaultOptions;
  }

  getSortOptionsNew() {
    const defaultOptions = [
      {
        label: 'Most Relevant',
        value: 'most_relevant',
      },
      {
        label: 'Most Downloaded',
        value: 'most_downloaded',
      },
      {
        label: 'Most Recent',
        value: 'most_recent',
      },
      {
        label: 'Trending Now',
        value: 'trending_now',
      },
      {
        label: 'Undiscovered',
        value: 'undiscovered',
      },
    ];

    const audioOptions = [
      {
        label: 'Duration: Low-High',
        value: 'duration_asc',
      },
      {
        label: 'Duration: High-Low',
        value: 'duration_desc',
      },
      {
        label: 'Title: A-Z',
        value: 'title_asc',
      },
      {
        label: 'Title: Z-A',
        value: 'title_desc',
      },
    ];

    return getCurrentSite() === SiteEnum.Audioblocks
      ? [...defaultOptions, ...audioOptions]
      : defaultOptions;
  }

  /**
   * Extracts the sort type and sort order from ordered sort option keys
   *
   * Ordered sort options are keyed in the dropdown as SORT-TYPE_ORDER.
   * This method extracts the proper sort type and sort order, returning
   * them as a formatted tuple.
   *
   * If not an ordered sort option (i.e., keyed by SORT-TYPE), the
   * original sort type value and a sort order of null are returned.
   *
   * @param sortType
   * @returns {string[]}
   */
  getSearchApiTypeAndOrderFromSortOption(sortType = '') {
    const _idx = sortType.lastIndexOf('_');
    const sortOrder = sortType.slice(_idx + 1);

    switch (sortOrder) {
      case 'asc':
      case 'desc':
        const type = sortType.slice(0, _idx);
        return [type, sortOrder.toUpperCase()];
      default:
        return [sortType, null];
    }
  }

  handleSortChanged = (name, value) => {
    const [sortType, order] =
      this.getSearchApiTypeAndOrderFromSortOption(value);

    const updateFields = {
      [name]: sortType,
      sortOrder: order,
      page: 1, // Go back to page 1 when any filter is changed
      isPagination: false,
      searchOrigin: searchOrigins.SORT_BY,
    };

    const newSearchOptions =
      this.props.selectedSearchFilterOptions.update(updateFields);

    this.props.setAudioFiltersChanged(true);
    this.props.updateSearchOptionsAndRefresh(newSearchOptions);
    this.props.collapseMoreLikeThisDrawer();
  };

  getIsDisabled() {
    return this.props.selectedSearchFilterOptions.similarTo > 0;
  }

  shouldShowRelatedSearch() {
    const { contentType } = this.props.selectedSearchFilterOptions;

    return (
      !this.props.searchIsLoading &&
      this.props.relatedSearch &&
      this.props.relatedSearch.length > 0 &&
      contentType !== SearchFilterContentTypes.Templates &&
      contentType !== SearchFilterContentTypes.Motion_bgs
    );
  }

  getRelatedSearchContainer() {
    if (!this.shouldShowRelatedSearch()) {
      return null;
    }

    return <RelatedSearchContainer />;
  }
}

function mapStateToProps(state) {
  const pagination = selectPagination(state);
  const { pproPluginEnabled } = selectAuthPermissions(state);

  return {
    canonicalLink: selectCanonicalLink(state),
    searchDescription: selectSearchDescription(state),
    searchTerm: selectSearchTerm(state),
    searchDescriptionWithoutSearchTerm:
      selectSearchDescriptionWithoutSearchTerm(state),
    hideSearchResults: selectHideSearchResults(state),
    searchIsLoading: selectSearchIsLoading(state),
    selectedSearchFilterOptions: selectSearchFilterOptions(state),
    relatedSearch: selectRelatedSearch(state),
    features: selectSearchFeatures(state),
    pageHeading: selectSearchPageHeading(state),
    isLoggedIn: selectIsLoggedIn(state),
    pproPluginEnabled,
    primarySubscription: selectPrimarySubscription(state),
    monthlyDownloadsRemaining: selectMonthlyDownloadsRemaining(state),
    context: selectSearchContext(state),
    sslid: selectSslid(state),
    numberOfResults: pagination.totalResults,
    selectedSuggestionsQuery: selectSuggestionsQuery(state),
    shouldUseSearchHeaderMicrofrontend:
      selectUseSearchHeaderMicrofrontend(state),
  };
}

export default connect(mapStateToProps, {
  updateMonthlyDownloadsRemaining,
  setIsSideMenuOpened,
  setAudioFiltersChanged,
  updateSearchOptionsAndRefresh,
  collapseMoreLikeThisDrawer,
  // @ts-ignore
})(SearchContainer);
