import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDown } from '@videoblocks/react-icons';

import Collapse from '../../../../../common/components/Collapse/Collapse';

const getHtmlSafeName = (rawName) => {
  return rawName.replace(/\W/g, '');
};

export interface MobileCollapsibleFilterProps {
  name?: string;
  invalidSelectionMessage?: ReactNode;
  collapsed: boolean;
  toggleCollapsed: () => void;
  hideSelected?: boolean;
  selectedOption?: ReactNode;
}

const MobileCollapsibleFilter: React.FC<MobileCollapsibleFilterProps> = ({
  name,
  invalidSelectionMessage,
  collapsed,
  toggleCollapsed,
  children,
  hideSelected,
  selectedOption,
}) => {
  const headingClassName = twMerge(
    'font-semibold text-base flex w-full justify-between items-center',
    invalidSelectionMessage !== null && 'invalid',
    invalidSelectionMessage && 'mb-1',
    !invalidSelectionMessage && !collapsed && 'mb-3'
  );

  const iconClassName = twMerge(
    'fill-current text-black w-3 h-3 transform transition duration-300 ease-in-out rotate-0',
    !collapsed && 'rotate-180',
    invalidSelectionMessage && 'hidden'
  );

  const containerClassName = twMerge(
    'bg-white p-4',
    !collapsed && 'bg-gray-110'
  );

  const htmlSafeName = getHtmlSafeName(name);

  return (
    <div className={containerClassName}>
      <div
        role="button"
        tabIndex={0}
        onClick={toggleCollapsed}
        onKeyDown={toggleCollapsed}
        aria-controls={htmlSafeName}
        aria-expanded={collapsed ? 'false' : 'true'}
        className={headingClassName}
      >
        <span>{name}</span>
        <ChevronDown className={iconClassName} />
      </div>
      {invalidSelectionMessage ? (
        <span className="text-gray-550">{invalidSelectionMessage}</span>
      ) : (
        <>
          <Collapse isCollapsed={collapsed} controlId={htmlSafeName}>
            {children}
          </Collapse>
          {selectedOption && collapsed && !hideSelected && (
            <div className="ml-4">{selectedOption}</div>
          )}
        </>
      )}
    </div>
  );
};

export default MobileCollapsibleFilter;
