import { createSelector } from '@reduxjs/toolkit';
import React from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import {
  selectSearchFilterOptions,
  selectHideSearchResults,
  selectSuggestionsQuery,
  selectSearchFeatures,
} from '../../selectors/searchSelectors';

const resultCountSelector = createSelector(
  selectSearchFilterOptions,
  selectHideSearchResults,
  selectSuggestionsQuery,
  (searchFilterOptions, hideSearchResults, suggestionsQuery) => ({
    searchFilterOptions,
    hideSearchResults,
    suggestionsQuery,
  })
);

const ResultCount: React.FC<{
  numberOfResults: number;
  className?: string;
}> = ({ numberOfResults, className = '' }) => {
  const { searchFilterOptions, hideSearchResults, suggestionsQuery } =
    useSelector(resultCountSelector);
  const features = useSelector(selectSearchFeatures);

  const { searchTerm } = searchFilterOptions;

  const displaySearchTerm = suggestionsQuery ? suggestionsQuery : searchTerm;

  let countString = hideSearchResults
    ? '0'
    : numberOfResults?.toLocaleString('en-US');
  const resultString = numberOfResults === 1 ? 'result' : 'results';
  const searchTermString = !!displaySearchTerm.length
    ? ` for ${displaySearchTerm}`
    : '';

  if (!hideSearchResults && numberOfResults === 10000) {
    countString += '+';
  }
  const resultCountString = features?.useRemainingSearchUI
    ? `${countString} ${resultString} found${searchTermString}`
    : `(${countString} ${resultString} found${searchTermString})`;

  return (
    <h3
      className={twMerge(
        'results-count whitespace-nowrap text-sm font-normal sm:whitespace-normal text-gray-550',
        className
      )}
    >
      {resultCountString}
    </h3>
  );
};

export default ResultCount;
