const FEATURE_LIMITS_URL_ROOT = '/feature-limits';

const featureLimitsUrl = (subscriptionUniqueId) =>
  subscriptionUniqueId
    ? `${FEATURE_LIMITS_URL_ROOT}?subscriptionUniqueId=${subscriptionUniqueId}`
    : FEATURE_LIMITS_URL_ROOT;

export async function getFeatureLimits(subscriptionUniqueId) {
  const res = await fetch(featureLimitsUrl(subscriptionUniqueId), {
    cache: 'no-cache',
  });
  return await res.json();
}

export async function getMonthlyDownloadsRemaining(subscriptionUniqueId) {
  const { monthlyDownloadsRemaining } =
    await getFeatureLimits(subscriptionUniqueId);
  return monthlyDownloadsRemaining;
}
