import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  toggleMobileFilterModalOpen,
  toggleMobileSortModalOpen,
} from '../../actions/SearchActions';
import {
  selectMobileFilterModalOpen,
  selectMobileSortModalOpen,
} from '../../selectors/searchSelectors';
import MobileFilterButton from './MobileFilterButton';
import MobileSortFilterButton from './MobileSortButton';

const MobileButtonsForFilters = () => {
  const mobileSortModalOpen = useSelector(selectMobileSortModalOpen);
  const mobileFilterModalOpen = useSelector(selectMobileFilterModalOpen);
  const dispatch = useDispatch();

  const handleFilterButtonClick = () => {
    dispatch(toggleMobileFilterModalOpen(!mobileFilterModalOpen));
  };
  const handleSortButtonClick = () => {
    dispatch(toggleMobileSortModalOpen(!mobileSortModalOpen));
  };

  return (
    <>
      <div className="md:hidden flex justify-between bg-white sticky top-0 px-4 py-4">
        <MobileFilterButton handleClick={handleFilterButtonClick} />
        <MobileSortFilterButton handleClick={handleSortButtonClick} />
      </div>
    </>
  );
};

export default MobileButtonsForFilters;
