import React from 'react';

const MobileSortFilterButton = (props): JSX.Element => {
  const { handleClick } = props;
  const iconClassName = 'fill-current text-black w-3 h-3';

  return (
    <button className="w-fit px-1 border-0 w-max" onClick={handleClick}>
      <div className="flex items-center text-sm">
        <img
          alt="Sort"
          src="/assets/common/images/icons/sort-icon.svg"
          className={iconClassName}
          width={12}
          height={12}
        />
        <span className="mt-0.5 ml-2">Sort</span>
      </div>
    </button>
  );
};

export default MobileSortFilterButton;
