export const ALL_VIDEOS_CONTENT_TYPE = 'all-videos';
export const MUSIC_CONTENT_TYPE = 'music';
export const FOOTAGE_CONTENT_TYPE = 'footage';
export const BACKGROUND_CONTENT_TYPE = 'motion-backgrounds';
export const TEMPLATE_CONTENT_TYPE = 'templates';
export const SOUND_EFFECTS_CONTENT_TYPE = 'sfx';
export const ALL_AUDIO_CONTENT_TYPE = 'all-audio';
export const ALL_IMAGES_CONTENT_TYPE = 'all-images';

export const VideoFiltersOptions = {
  CONTENT_TYPE: 'contentType',
  VIDEO_QUALITY: 'videoQuality',
  TEMPLATE_TYPE: 'templateType',
  DURATION: 'duration',
  MEDIA_DETAILS: 'mediaDetails',
  PROPERTY_RELEASED: 'propertyReleased',
  TALENT_RELEASED: 'talentReleased',
  CATEGORIES: 'categories',
  FRAME_RATES: 'frameRates',
  MIN_DURATION: 'minDuration',
  MAX_DURATION: 'maxDuration',
};

export const VideoAllFiltersOptions = {
  CONTENT_TYPE: 'contentType',
  VIDEO_QUALITY: 'videoQuality',
  DURATION: 'duration',
  PROPERTY_RELEASED: 'propertyReleased',
  TALENT_RELEASED: 'talentReleased',
  FRAME_RATES: 'frameRates',
};

export const VideoFootageFiltersOptions = {
  CONTENT_TYPE: 'contentType',
  VIDEO_QUALITY: 'videoQuality',
  DURATION: 'duration',
  PROPERTY_RELEASED: 'propertyReleased',
  TALENT_RELEASED: 'talentReleased',
  CATEGORIES: 'categories',
  FRAME_RATES: 'frameRates',
};

export const VideoBackgroundsFiltersOptions = {
  CONTENT_TYPE: 'contentType',
  VIDEO_QUALITY: 'videoQuality',
  DURATION: 'duration',
  PROPERTY_RELEASED: 'propertyReleased',
  TALENT_RELEASED: 'talentReleased',
  CATEGORIES: 'categories',
  FRAME_RATES: 'frameRates',
};

export const VideoAfterAffectsFiltersOptions = {
  CONTENT_TYPE: 'contentType',
  VIDEO_QUALITY: 'videoQuality',
  DURATION: 'duration',
  PROPERTY_RELEASED: 'propertyReleased',
  TALENT_RELEASED: 'talentReleased',
  CATEGORIES: 'categories',
  FRAME_RATES: 'frameRates',
  TEMPLATE_TYPE: 'templateType',
};

export const VideoFiltersByContentType = {
  [ALL_VIDEOS_CONTENT_TYPE]: VideoAllFiltersOptions,
  [FOOTAGE_CONTENT_TYPE]: VideoFootageFiltersOptions,
  [BACKGROUND_CONTENT_TYPE]: VideoBackgroundsFiltersOptions,
  [TEMPLATE_CONTENT_TYPE]: VideoAfterAffectsFiltersOptions,
};

export const AudioFiltersOptions = {
  CONTENT_TYPE: 'contentType',
  SFX_CATEGORIES: 'sfxCategories',
  MUSIC_MOODS: 'musicMoods',
  MUSIC_GENRES: 'musicGenres',
  MUSIC_INSTRUMENTS: 'musicInstruments',
  VOCAL_TYPE: 'vocalType',
  TEMPO: 'tempo',
  DURATION: 'duration',
};

export const AudioMusicFiltersOptions = {
  CONTENT_TYPE: 'contentType',
  MUSIC_MOODS: 'musicMoods',
  MUSIC_GENRES: 'musicGenres',
  MUSIC_INSTRUMENTS: 'musicInstruments',
  VOCAL_TYPE: 'vocalType',
  TEMPO: 'tempo',
  DURATION: 'duration',
};

export const AudioAllFiltersOptions = {
  CONTENT_TYPE: 'contentType',
};

export const AudioSfxFiltersOptions = {
  CONTENT_TYPE: 'contentType',
  DURATION: 'duration',
  SFX_CATEGORIES: 'sfxCategories',
};

export const AudioFiltersByContentType = {
  [MUSIC_CONTENT_TYPE]: AudioMusicFiltersOptions,
  [ALL_AUDIO_CONTENT_TYPE]: AudioAllFiltersOptions,
  [SOUND_EFFECTS_CONTENT_TYPE]: AudioSfxFiltersOptions,
};

export const ImageFiltersOptions = {
  CATEGORIES: 'categories',
  CONTENT_TYPE: 'contentType',
  ORIENTATION: 'orientation',
  COLOR: 'color',
  MEDIA_DETAILS: 'mediaDetails',
  PROPERTY_RELEASED: 'propertyReleased',
  TALENT_RELEASED: 'talentReleased',
  FILE_TYPE: 'fileType',
  HAS_TRANSPARENCY: 'hasTransparency',
};
