import React from 'react';

import { Funnel } from '@videoblocks/react-icons';

const MobileFilterButton = (props): JSX.Element => {
  const { handleClick } = props;
  const iconClassName = 'fill-current text-black w-3 h-3';

  return (
    <button className="w-fit px-1 border-0 w-max" onClick={handleClick}>
      <div className="flex items-center text-sm">
        <Funnel className={iconClassName} />
        <span className="mt-0.5 ml-2">Filter</span>
      </div>
    </button>
  );
};

export default MobileFilterButton;
