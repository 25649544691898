import React, { Component } from 'react';
import Dropdown from 'react-dropdown';

import { ChevronDown, ChevronUp } from '@videoblocks/react-icons';

import 'react-dropdown/style.css';

interface Props {
  name: string;
  value: string;
  label?: string;
  disabledLabel?: string;
  options?: Record<string, string>;
  onChange: (name: string, value) => void;
  disabled?: boolean;
  sortOptions?: boolean;
  classNames?: string[];
}

export default class DropdownFilter extends Component<Props> {
  render() {
    const {
      name,
      value,
      label,
      options,
      disabled = false,
      disabledLabel,
    } = this.props;

    const { classNames = [] } = this.props;
    classNames.push('DropdownFilter');
    classNames.push('select-' + name);
    classNames.push('hidden md:flex');

    return (
      <div className={classNames.join(' ')} id={'select-' + name}>
        <span className="DropdownFilter-label">{label}</span>

        <Dropdown
          value={value}
          options={this.getFormattedOptions()}
          onChange={this.handleSelectChanged}
          disabled={!options || Object.keys(options).length <= 0 || disabled}
          placeholder={disabledLabel || 'Select...'}
          controlClassName="border-0"
          arrowClosed={<ChevronDown className="w-3 h-3 m-1.5" />}
          arrowOpen={<ChevronUp className="w-3 h-3 m-1.5" />}
        />
      </div>
    );
  }

  handleSelectChanged = (event) => {
    this.props.onChange(this.props.name, event.value);
  };

  getFormattedOptions = () => {
    if (!this.props.options) {
      return [];
    }

    const keys = Object.keys(this.props.options);

    if (this.props.sortOptions) {
      keys.sort();
    }

    return keys.map((key) => {
      return {
        label: this.props.options[key],
        value: key,
      };
    });
  };
}
