import React from 'react';

interface FilterButtonProps {
  isOpen: boolean;
  closeSideMenu: () => void;
  openSideMenu: () => void;
  numFilters?: number;
}

export default function FilterButton({
  isOpen,
  closeSideMenu,
  openSideMenu,
  numFilters = 0,
}: FilterButtonProps) {
  const [isHovered, setIsHovered] = React.useState(false);
  const buttonClass = isOpen
    ? 'bg-gray-800 text-white hover:bg-gray-900 border-transparent'
    : 'bg-white text-gray-900 hover:bg-gray-900 hover:text-white hover:border-transparent border-gray-250 border-solid';

  return (
    <button
      className={`relative flex flex-shrink-0 items-center p-4 text-sm font-semibold leading-none whitespace-nowrap rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-white 
        ${buttonClass}`}
      onClick={isOpen ? closeSideMenu : openSideMenu}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{ borderWidth: '1px' }}
    >
      <div className="flex gap-2 items-center self-stretch my-auto">
        <img
          loading="lazy"
          src={`${__ASSETS_COMMON_IMAGES_URL__}/icons/${
            isOpen || isHovered ? 'filter-group-white.svg' : 'filter-group.svg'
          }`}
          className={`object-contain shrink-0 self-stretch my-auto w-4 aspect-square`}
          alt="filter button"
        />
        <span className="self-stretch my-auto">Filters</span>
      </div>
      {!!numFilters && (
        <div className="absolute rounded-full right-0 top-0 bg-blue-200 w-5 h-5 m-auto flex items-center justify-center -right-2 -top-2 text-sm text-blue-900">
          {numFilters}
        </div>
      )}
    </button>
  );
}
