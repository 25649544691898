import React, { useState, useEffect, useRef, useCallback } from 'react';

function getAssetSource(src: string = '') {
  if (src === '') return '';

  return src?.startsWith('http')
    ? src
    : `${__ASSETS_COMMON_IMAGES_URL__}${src?.startsWith('/') ? '' : '/'}${src}`;
}

const BackgroundVideo = ({
  videoSrc,
  posterSrc,
  idx,
}: {
  videoSrc: string;
  posterSrc: string;
  idx: number;
}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleIntersection = useCallback<IntersectionObserverCallback>(
    (entries) => {
      for (const entry of entries) {
        const video = entry.target as HTMLVideoElement;
        const isVideoIntersecting = entry.isIntersecting;

        setIsIntersecting(isVideoIntersecting);

        if (isVideoIntersecting) {
          video.play();
        } else {
          video.pause();
        }
      }
    },
    [setIsIntersecting]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection);
    const currentRef = videoRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [handleIntersection, videoRef]);

  const src = getAssetSource(videoSrc);
  const poster = getAssetSource(posterSrc);

  return (
    <video
      ref={videoRef}
      className="hero-video w-full h-full object-cover"
      poster={poster}
      loop
      muted
      preload={idx === 0 ? '' : 'metadata'}
    >
      {isIntersecting && <source src={src} type="video/mp4" />}
    </video>
  );
};

export default BackgroundVideo;
