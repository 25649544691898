import React from 'react';

interface SortIconProps {
  type?: 'dark' | 'light';
}
const SortIcon: React.FC<SortIconProps> = ({ type = 'dark' }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_4047_25704)">
        <path
          d="M12.7075 15.0268L12.7075 5.93343C12.7075 5.88922 12.7326 5.84683 12.7773 5.81557C12.8221 5.78432 12.8827 5.76676 12.9459 5.76676L14.0807 5.76676C14.2413 5.76334 14.3981 5.7316 14.5365 5.67449C14.6749 5.61738 14.7904 5.53675 14.8722 5.44009C14.9559 5.33875 15 5.22378 15 5.10676C15 4.98973 14.9559 4.87477 14.8722 4.77343L12.1258 1.60009C12.0382 1.51072 11.9208 1.43761 11.7837 1.3871C11.6467 1.33658 11.494 1.31018 11.3391 1.31018C11.1841 1.31018 11.0315 1.33658 10.8944 1.3871C10.7573 1.43761 10.6399 1.51072 10.5523 1.60009L7.81545 4.79343C7.72974 4.89438 7.68368 5.00937 7.68194 5.12676C7.68123 5.23719 7.72413 5.34561 7.80591 5.44009C7.88779 5.53675 8.00328 5.61738 8.14165 5.67449C8.28003 5.7316 8.4368 5.76334 8.59742 5.76676L9.73222 5.76676C9.79545 5.76676 9.85609 5.78432 9.9008 5.81557C9.94551 5.84683 9.97063 5.88922 9.97063 5.93343L9.97063 15.0268C9.95481 15.1591 9.97845 15.2923 10.04 15.4179C10.1016 15.5436 10.1998 15.6589 10.3285 15.7566C10.4571 15.8544 10.6133 15.9324 10.7872 15.9858C10.9612 16.0391 11.1491 16.0667 11.3391 16.0667C11.529 16.0667 11.7169 16.0391 11.8909 15.9858C12.0649 15.9324 12.2211 15.8544 12.3497 15.7566C12.4783 15.6589 12.5765 15.5436 12.6381 15.4179C12.6997 15.2923 12.7233 15.1591 12.7075 15.0268Z"
          fill={`${type === 'dark' ? '#1F2830' : 'white'}`}
        />
        <path
          d="M4.78285 14.4001L7.49113 11.2067C7.5644 11.1085 7.60148 10.999 7.599 10.8882C7.59653 10.7774 7.55458 10.6687 7.47696 10.5721C7.39933 10.4755 7.28848 10.3939 7.15442 10.3348C7.02036 10.2756 6.86733 10.2408 6.70916 10.2334L5.58389 10.2334C5.52066 10.2334 5.46002 10.2158 5.41531 10.1846C5.3706 10.1533 5.34549 10.1109 5.34549 10.0667L5.34549 0.973402C5.3613 0.841058 5.33767 0.707858 5.27608 0.582226C5.21449 0.456594 5.11628 0.341262 4.98767 0.243523C4.85905 0.145784 4.70283 0.0677634 4.52887 0.0143951C4.35491 -0.0389732 4.16701 -0.0665285 3.97704 -0.0665285C3.78707 -0.0665285 3.59917 -0.0389732 3.42521 0.0143951C3.25126 0.0677633 3.09503 0.145784 2.96642 0.243523C2.8378 0.341262 2.73959 0.456594 2.678 0.582226C2.61641 0.707858 2.59278 0.841057 2.6086 0.973402L2.6086 10.0667C2.6086 10.1109 2.58348 10.1533 2.53877 10.1846C2.49406 10.2158 2.43342 10.2334 2.37019 10.2334L1.23538 10.2334C1.07313 10.2357 0.914411 10.2669 0.774236 10.3241C0.634061 10.3812 0.517071 10.4625 0.43434 10.5601C0.359989 10.6564 0.320688 10.764 0.319906 10.8734C0.32165 10.9908 0.367705 11.1058 0.453413 11.2067L3.1903 14.4001C3.2747 14.4921 3.39016 14.5682 3.52652 14.6217C3.66288 14.6751 3.81596 14.7044 3.97227 14.7067C4.13344 14.7077 4.29232 14.68 4.43407 14.6264C4.57583 14.5728 4.69582 14.4949 4.78285 14.4001Z"
          fill={`${type === 'dark' ? '#1F2830' : 'white'}`}
        />
      </g>
      <defs>
        <clipPath id="clip0_4047_25704">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(16) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SortIcon;
