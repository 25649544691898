export enum FilterInputType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

export enum FilterGroupNames {
  CONTENT_TYPE = 'contentType',
  USAGE_RIGHTS = 'usageRights',
}

export enum AudioFilterGroupNames {
  SFX_CATEGORIES = 'sfxCategories',
  GENRES = 'musicGenres',
  INSTRUMENTS = 'musicInstruments',
  MOODS = 'musicMoods',
  VOCAL = 'vocalType',
}

export enum ImageFilterGroupNames {
  CATEGORIES = 'categories',
  ORIENTATION = 'orientation',
  FILETYPE = 'fileType',
}

export enum VideoFilterGroupNames {
  CATEGORIES = 'categories',
  TEMPLATES = 'templateType',
  FRAME_RATES = 'frameRates',
  RESOLUTION = 'videoQuality',
}
