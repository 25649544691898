import React from 'react';

import { VideoSelectedSearchFilterOptions } from '../../../containers/MenuContainerInterfaces';
import { qualities } from '../../../entities/VideoSearchFilterOptions';
import { VideoFilterGroupNames } from '../../menu/common/MenuEnums';
import RadioGroup from '../../menu/common/RadioGroup';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const convertSelectedOptionsToText = (selectedOption, allOptions) => {
  const selectedLabels = allOptions
    .filter((option) => {
      return option.value === selectedOption;
    })
    .map((option) => {
      return option.label;
    });

  return selectedLabels.length > 0 ? selectedLabels[0] : '';
};

interface MobileMediaDetailsFilterProps {
  onChange: (name: string, value: string | number) => void;
  selectedSearchFilterOptions: VideoSelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
}

const MobileResolutionFilter = (
  props: MobileMediaDetailsFilterProps
): JSX.Element => {
  const { onChange, selectedSearchFilterOptions, collapsed, toggleCollapsed } =
    props;

  return (
    <div className="flex flex-col">
      <MobileCollapsibleFilter
        name="Resolution"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSelectedOptionsToText(
          selectedSearchFilterOptions.videoQuality,
          qualities
        )}
      >
        <RadioGroup
          groupName={VideoFilterGroupNames.RESOLUTION}
          allOptions={qualities}
          checkedOption={selectedSearchFilterOptions.videoQuality}
          onChange={onChange}
        />
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileResolutionFilter;
