import React from 'react';

import { ImageSelectedSearchFilterOptions } from '../../../containers/MenuContainerInterfaces';
import { orientations } from '../../../entities/ImageSearchFilterOptions';
import { ImageFilterGroupNames } from '../../menu/common/MenuEnums';
import RadioGroup from '../../menu/common/RadioGroup';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const convertSelectedOptionsToText = (selectedOption, allOptions) => {
  const selectedLabels = allOptions
    .filter((option) => {
      return option.value === selectedOption;
    })
    .map((option) => {
      return option.label;
    });
  return selectedLabels.length > 0 ? selectedLabels[0] : '';
};

interface MobileOrientationFilterProps {
  onChange: (name: string, value: string | number) => void;
  selectedSearchFilterOptions: ImageSelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
}

const MobileOrientationFilter = (
  props: MobileOrientationFilterProps
): JSX.Element => {
  const { onChange, selectedSearchFilterOptions, collapsed, toggleCollapsed } =
    props;

  return (
    <div className="flex flex-col">
      <MobileCollapsibleFilter
        name="Orientation"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSelectedOptionsToText(
          selectedSearchFilterOptions.orientation,
          orientations
        )}
      >
        <RadioGroup
          groupName={ImageFilterGroupNames.ORIENTATION}
          allOptions={orientations}
          checkedOption={selectedSearchFilterOptions.orientation}
          onChange={onChange}
        />
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileOrientationFilter;
