import React from 'react';
import { twMerge } from 'tailwind-merge';

import { contentTypes } from '../../../../../common/Constants';
import { AudioSelectedSearchFilterOptions } from '../../../containers/MenuContainerInterfaces';
import { genres } from '../../../entities/AudioSearchFilterOptions';
import CheckboxGroup from '../../menu/common/CheckboxGroup';
import { AudioFilterGroupNames } from '../../menu/common/MenuEnums';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const convertSelectedOptionsToText = (checkedValues, options) => {
  const selectedLabels = options
    .filter((option) => checkedValues.includes(option.value))
    .map((option) => {
      return (
        <React.Fragment key={option.value}>
          {option.label}
          <br />
        </React.Fragment>
      );
    });

  return selectedLabels;
};

interface MobileGenresFilterFilterProps {
  onChange: (name: string, value: string | number) => void;
  selectedSearchFilterOptions: AudioSelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
  className?: string;
}

const MobileGenresFilter = (
  props: MobileGenresFilterFilterProps
): JSX.Element => {
  const {
    onChange,
    selectedSearchFilterOptions,
    collapsed,
    toggleCollapsed,
    className,
  } = props;

  const { contentType } = selectedSearchFilterOptions;

  const invalidSelectionMessage =
    contentType !== contentTypes.MUSIC && 'Select Music to see Genres';

  return (
    <div className={twMerge('flex flex-col', className)}>
      <MobileCollapsibleFilter
        name="Genres"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSelectedOptionsToText(
          selectedSearchFilterOptions.musicGenres,
          genres
        )}
        invalidSelectionMessage={invalidSelectionMessage}
      >
        <CheckboxGroup
          groupName={AudioFilterGroupNames.GENRES}
          allOptions={genres}
          selectedOptions={selectedSearchFilterOptions.musicGenres}
          onChange={onChange}
        />
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileGenresFilter;
