import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalDialog from '@videoblocks/shared-components/dist/mjs/components/Shared/ModalDialog';

import { setAudioFiltersChanged } from '../../../../Audio/actions/AudioActions';
import { getCurrentSite } from '../../../../common/utils';
import { searchOrigins } from '../../../Shared/constants';
import {
  toggleMobileSortModalOpen,
  updateSearchOptionsAndRefresh,
} from '../../actions/SearchActions';
import {
  selectMobileSortModalOpen,
  selectSearchFilterOptions,
} from '../../selectors/searchSelectors';
import RadioGroup from '../menu/common/RadioGroup';

const getSortOptions = () => {
  const defaultOptions = {
    most_relevant: 'Most Relevant',
    most_downloaded: 'Most Downloaded',
    most_recent: 'Most Recent',
    trending_now: 'Trending Now',
    undiscovered: 'Undiscovered',
  };

  const audioOptions = {
    duration_asc: 'Duration: Low-High',
    duration_desc: 'Duration: High-Low',
    title_asc: 'Title: A-Z',
    title_desc: 'Title: Z-A',
  };

  return getCurrentSite() === 'audioblocks'
    ? { ...defaultOptions, ...audioOptions }
    : defaultOptions;
};

const getFormattedOptions = (options) => {
  if (!options) {
    return [];
  }

  const keys = Object.keys(options);

  return keys.map((key) => {
    return {
      label: options[key],
      value: key,
    };
  });
};

const getSearchApiTypeAndOrderFromSortOption = (sortType = '') => {
  const _idx = sortType.lastIndexOf('_');
  const sortOrder = sortType.slice(_idx + 1);

  switch (sortOrder) {
    case 'asc':
    case 'desc':
      const type = sortType.slice(0, _idx);
      return [type, sortOrder.toUpperCase()];
    default:
      return [sortType, null];
  }
};

const getTopOffsetClassNames = () => {
  if (getCurrentSite() === 'audioblocks') {
    return 'top-1/4';
  }

  return 'top-1/2';
};

const MobileSortModal = (): JSX.Element => {
  const dispatch = useDispatch();
  const selectedSearchFilterOptions = useSelector(selectSearchFilterOptions);
  const mobileSortModalOpen = useSelector(selectMobileSortModalOpen);
  const { sort, sortOrder } = selectedSearchFilterOptions;
  const filterValue = sortOrder ? `${sort}_${sortOrder.toLowerCase()}` : sort;
  const handleChange = (name, value) => {
    const [sortType, order] = getSearchApiTypeAndOrderFromSortOption(value);

    const updateFields = {
      [name]: sortType,
      sortOrder: order,
      page: 1, // Go back to page 1 when any filter is changed
      isPagination: false,
      searchOrigin: searchOrigins.SORT_BY,
    };

    const newSearchOptions = selectedSearchFilterOptions.update(updateFields);

    dispatch(setAudioFiltersChanged(true));
    dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
    dispatch(toggleMobileSortModalOpen(false));
  };

  return (
    <ModalDialog
      isOpen={mobileSortModalOpen}
      style={{
        content: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        },
      }}
      parentSelector={() => document.querySelector('#search-results-app')}
      topOffsetClass={getTopOffsetClassNames()}
      contentLabel={'Sort'}
      onRequestClose={() => dispatch(toggleMobileSortModalOpen(false))}
      className="p-0 rounded-none flex flex-col"
      closeButtonClassName="top-4"
      contentLabelClassName="text-center my-2.5"
      contentLabelContainerClassName="mb-0"
    >
      <div className="flex flex-col flex-grow border-gray-150 mobile-filters-container">
        <RadioGroup
          groupName="sort"
          allOptions={getFormattedOptions(getSortOptions())}
          checkedOption={filterValue}
          onChange={handleChange}
          customClassName="mb-0 items-center border-solid border border-gray-150 flex-grow pl-4"
          labelClassName="text-lg"
          inputClassName="bg-cover h-5 w-5"
        />
      </div>
    </ModalDialog>
  );
};

export default MobileSortModal;
