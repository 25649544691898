import React from 'react';

import { VideoSelectedSearchFilterOptions } from '../../../containers/MenuContainerInterfaces';
import { frameRates as frameRateOptions } from '../../../entities/VideoSearchFilterOptions';
import CheckboxGroup from '../../menu/common/CheckboxGroup';
import { VideoFilterGroupNames } from '../../menu/common/MenuEnums';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const convertSelectedOptionsToText = (checkedValues, options) => {
  const selectedLabels = options
    .filter((option) => checkedValues.includes(option.value))
    .map((option) => {
      return (
        <React.Fragment key={option.value}>
          {option.label}
          <br />
        </React.Fragment>
      );
    });

  return selectedLabels;
};

interface MobileFrameRatesFilterProps {
  onChange: (name: string, value: string | number) => void;
  selectedSearchFilterOptions: VideoSelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
}

const MobileFrameRatesFilter = (
  props: MobileFrameRatesFilterProps
): JSX.Element => {
  const { onChange, selectedSearchFilterOptions, collapsed, toggleCollapsed } =
    props;
  const { frameRates } = selectedSearchFilterOptions;
  return (
    <div className="flex flex-col">
      <MobileCollapsibleFilter
        name="Frame Rate"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSelectedOptionsToText(
          frameRates,
          frameRateOptions
        )}
      >
        <CheckboxGroup
          groupName={VideoFilterGroupNames.FRAME_RATES}
          allOptions={frameRateOptions}
          selectedOptions={selectedSearchFilterOptions.frameRates}
          onChange={onChange}
        />
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileFrameRatesFilter;
