import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import CheckboxGroup from '../../menu/common/CheckboxGroup';
import { FilterInputType } from '../../menu/common/MenuEnums';
import { FilterOption } from '../../menu/common/MenuInterfaces';
import RadioGroup from '../../menu/common/RadioGroup';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const convertSelectedOptionsToText = (selectedOption, allOptions) => {
  const selectedLabels = allOptions
    .filter((option) => {
      return option.value === selectedOption;
    })
    .map((option) => {
      return option.label;
    });

  return selectedLabels.length > 0 ? selectedLabels[0] : '';
};

interface MobileCategoriesFilterProps {
  onChange: (name: string, value: string | number) => void;
  invalidSelectionMessage: ReactNode;
  allOptions: FilterOption[];
  checkedOption?: string;
  collapsed: boolean;
  toggleCollapsed: () => void;
  className?: string;
  inputType: FilterInputType;
  selectedOptions?: string[];
  groupName: string;
}

const MobileCategoriesFilter = (
  props: MobileCategoriesFilterProps
): JSX.Element => {
  const {
    onChange,
    invalidSelectionMessage,
    allOptions,
    checkedOption,
    collapsed,
    toggleCollapsed,
    className,
    inputType,
    selectedOptions,
    groupName,
  } = props;

  return (
    <div className={twMerge('flex flex-col', className)}>
      <MobileCollapsibleFilter
        name="Categories"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSelectedOptionsToText(checkedOption, allOptions)}
        invalidSelectionMessage={invalidSelectionMessage}
      >
        {inputType === FilterInputType.RADIO ? (
          <RadioGroup
            groupName={groupName}
            allOptions={allOptions}
            checkedOption={checkedOption}
            onChange={onChange}
          />
        ) : (
          <CheckboxGroup
            groupName={groupName}
            selectedOptions={selectedOptions}
            onChange={onChange}
            allOptions={allOptions}
          />
        )}
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileCategoriesFilter;
