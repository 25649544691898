import Range from 'rc-slider/lib/Range';
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { contentTypes } from '../../../../../common/Constants';
import SiteConstants from '../../../../../common/SiteConstants/SiteConstants';
import {
  AudioSelectedSearchFilterOptions,
  SelectedSearchFilterOptions,
} from '../../../containers/MenuContainerInterfaces';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const convertSliderValuesToText = (
  sliderOneValue,
  sliderTwoValue,
  minRangeValue,
  maxRangeValue
) => {
  if (sliderOneValue > minRangeValue || sliderTwoValue < maxRangeValue) {
    return `${sliderOneValue}bpm to ${sliderTwoValue}bpm`;
  }

  return '';
};

interface MobileTempoFilterProps {
  onAfterChange: (min: string | number, max: number | string) => void;
  selectedSearchFilterOptions: SelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
  className?: string;
}

const MobileTempoFilter = (props: MobileTempoFilterProps): JSX.Element => {
  const {
    selectedSearchFilterOptions,
    onAfterChange,
    collapsed,
    toggleCollapsed,
    className,
  } = props;

  const { tempoMin, tempoMax } =
    selectedSearchFilterOptions as AudioSelectedSearchFilterOptions;

  const minTempoValue = parseInt(SiteConstants.getInstance().getMinTempo(), 10);
  const maxTempoValue = parseInt(SiteConstants.getInstance().getMaxTempo(), 10);

  const tempoMinInt = Number(tempoMin);
  const tempoMaxInt = Number(tempoMax);

  const [state, setState] = useState({
    sliderOneValue: tempoMinInt || minTempoValue,
    sliderTwoValue: tempoMaxInt || maxTempoValue,
  });

  useEffect(() => {
    setState({
      sliderOneValue: tempoMinInt || minTempoValue,
      sliderTwoValue: tempoMaxInt || maxTempoValue,
    });
  }, [selectedSearchFilterOptions]);

  const { sliderOneValue, sliderTwoValue } = state;

  const onChange = (values: number[]): void =>
    setState({
      sliderOneValue: values[0] || minTempoValue,
      sliderTwoValue: values[1] || maxTempoValue,
    });

  const invalidSelectionMessage =
    selectedSearchFilterOptions.contentType !== contentTypes.MUSIC &&
    'Select Music to see Tempo';

  const handleAfterChange = (values: number[]): void => {
    const slOneValue = values[0];
    const slTwoValue = values[1];
    const max = slTwoValue >= maxTempoValue ? '' : slTwoValue;
    const min = slOneValue <= minTempoValue ? '' : slOneValue;
    onAfterChange(min, max);
  };

  return (
    <div className={twMerge('flex flex-col', className)}>
      <MobileCollapsibleFilter
        name="Tempo"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        invalidSelectionMessage={invalidSelectionMessage}
        selectedOption={convertSliderValuesToText(
          sliderOneValue,
          sliderTwoValue,
          minTempoValue,
          maxTempoValue
        )}
      >
        <div className="slider-container px-2">
          <Range
            className="tempo-slider"
            min={minTempoValue}
            max={maxTempoValue}
            step={1}
            defaultValue={[minTempoValue, maxTempoValue]}
            value={[sliderOneValue, sliderTwoValue]}
            onChange={onChange}
            onAfterChange={handleAfterChange}
            handleStyle={{
              height: '0.5rem',
              width: '0.5rem',
              backgroundColor: '#0F87FF',
            }}
            marks={{
              [minTempoValue]: {
                style: { left: '6%' },
                label: `${sliderOneValue} bpm`,
              },
              [maxTempoValue]: {
                style: { left: '90%' },
                label: `${sliderTwoValue}${
                  sliderTwoValue === maxTempoValue ? '+' : ''
                } bpm`,
              },
            }}
          />
        </div>
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileTempoFilter;
