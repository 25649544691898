import Range from 'rc-slider/lib/Range';
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import SiteConstants from '../../../../../common/SiteConstants/SiteConstants';
import {
  convertSecondsToMMSS,
  nextClosestMinuteInSeconds,
} from '../../../../../common/utils';
import { SelectedSearchFilterOptions } from '../../../containers/MenuContainerInterfaces';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const convertSliderValuesToText = (
  sliderOneValue,
  sliderTwoValue,
  minRangeValue,
  maxRangeValue
) => {
  if (sliderOneValue > minRangeValue || sliderTwoValue < maxRangeValue) {
    return (
      convertSecondsToMMSS(sliderOneValue) +
      ' to ' +
      convertSecondsToMMSS(sliderTwoValue)
    );
  }

  return '';
};

interface MobileDurationFilterProps {
  onAfterChange: (min: string | number, max: number | string) => void;
  selectedSearchFilterOptions: SelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
  className?: string;
}

const MobileDurationFilter = (
  props: MobileDurationFilterProps
): JSX.Element => {
  const {
    selectedSearchFilterOptions,
    onAfterChange,
    collapsed,
    toggleCollapsed,
    className,
  } = props;

  const { minDuration, maxDuration } = selectedSearchFilterOptions;

  const minDurationRangeValue = SiteConstants.getInstance().getMinDuration();
  const maxDurationRangeValue = SiteConstants.getInstance().getMaxDuration();

  const calculatedMinRangeInSeconds = nextClosestMinuteInSeconds(
    minDurationRangeValue
  );
  const calculatedMaxRangeInSeconds = nextClosestMinuteInSeconds(
    maxDurationRangeValue
  );

  const [state, setState] = useState({
    sliderOneValue: minDuration || calculatedMinRangeInSeconds,
    sliderTwoValue: maxDuration || calculatedMaxRangeInSeconds,
  });

  useEffect(() => {
    setState({
      sliderOneValue: minDuration || calculatedMinRangeInSeconds,
      sliderTwoValue: maxDuration || calculatedMaxRangeInSeconds,
    });
  }, [selectedSearchFilterOptions]);

  const { sliderOneValue, sliderTwoValue } = state;

  const onChangeSliders = (values: number[]): void => {
    const slOneValue = values[0];
    const slTwoValue = values[1];

    setState({
      sliderOneValue:
        slOneValue || nextClosestMinuteInSeconds(minDurationRangeValue),
      sliderTwoValue:
        slTwoValue || nextClosestMinuteInSeconds(maxDurationRangeValue),
    });
  };

  const handleAfterChange = (values: number[]): void => {
    const slOneValue = values[0];
    const slTwoValue = values[1];
    const max = slTwoValue >= calculatedMaxRangeInSeconds ? '' : slTwoValue;
    const min = slOneValue <= calculatedMinRangeInSeconds ? '' : slOneValue;
    onAfterChange(min, max);
  };

  return (
    <div className={twMerge('flex flex-col', className)}>
      <MobileCollapsibleFilter
        name="Duration"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSliderValuesToText(
          sliderOneValue,
          sliderTwoValue,
          minDurationRangeValue,
          maxDurationRangeValue
        )}
      >
        <div className="slider-container px-2">
          <Range
            className="duration-slider"
            min={minDurationRangeValue}
            max={maxDurationRangeValue}
            step={1}
            defaultValue={[minDurationRangeValue, maxDurationRangeValue]}
            value={[sliderOneValue, sliderTwoValue]}
            onChange={onChangeSliders}
            onAfterChange={handleAfterChange}
            marks={{
              [minDurationRangeValue]: {
                style: { left: '3%' },
                label: convertSecondsToMMSS(
                  sliderOneValue,
                  calculatedMaxRangeInSeconds
                ),
              },
              [maxDurationRangeValue]: {
                style: { left: '96%' },
                label: convertSecondsToMMSS(
                  sliderTwoValue,
                  calculatedMaxRangeInSeconds
                ),
              },
            }}
          />
        </div>
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileDurationFilter;
