import React from 'react';

import { ImageSelectedSearchFilterOptions } from '../../../containers/MenuContainerInterfaces';
import { ColorPicker } from '../../menu/filters/ColorFilter';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

import '../../filters/ColorFilter.less';

interface MobileColorFilterProps {
  onChange: (name: string) => void;
  selectedSearchFilterOptions: ImageSelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
}

const MobileColorFilter = (props: MobileColorFilterProps): JSX.Element => {
  const { onChange, selectedSearchFilterOptions, collapsed, toggleCollapsed } =
    props;

  return (
    <div className="flex flex-col mobile-color-filter-container">
      <MobileCollapsibleFilter
        name="Color"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={selectedSearchFilterOptions.color}
      >
        <div className="mobile-color-picker-container">
          <ColorPicker
            color={selectedSearchFilterOptions.color}
            onChange={onChange}
          />
        </div>
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileColorFilter;
