import { AnySearchFilterOptions } from '../containers/MenuContainerInterfaces';

type Updates = Pick<
  AnySearchFilterOptions,
  'searchOrigin' | 'sort' | 'sortOrder' | 'page'
>;

export default function buildContentAgnosticSearchOptions(
  queryParams: any
): Updates {
  const updates: Updates = {};

  // page
  if (queryParams.page) {
    updates.page = Number(queryParams.page);
  }

  // sort
  if (queryParams.sort) {
    updates.sort = queryParams.sort;
  }

  if (queryParams.sortOrder) {
    updates.sortOrder = queryParams.sortOrder;
  }

  // search origin
  if (queryParams['search-origin']) {
    updates.searchOrigin = queryParams['search-origin'];
  }

  return updates;
}
