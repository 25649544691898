import React from 'react';

import { ImageSelectedSearchFilterOptions } from '../../../containers/MenuContainerInterfaces';
import { fileTypes } from '../../../entities/ImageSearchFilterOptions';
import CheckboxGroup from '../../menu/common/CheckboxGroup';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const getSelectedFileTypes = (selectedSearchFilterOptions) => {
  const selectedFileTypes = [];
  selectedSearchFilterOptions.hasTransparency &&
    selectedFileTypes.push('hasTransparency');

  return selectedFileTypes;
};

const convertSelectedOptionsToText = (checkedValues, options) => {
  const selectedLabels = options
    .filter((option) => checkedValues.includes(option.value))
    .map((option) => {
      return (
        <React.Fragment key={option.value}>
          {option.label}
          <br />
        </React.Fragment>
      );
    });

  return selectedLabels;
};

interface MobileFileTypeFilterProps {
  onChange: (name: string, value: string | number) => void;
  selectedSearchFilterOptions: ImageSelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
}

const MobileFileTypeFilter = (
  props: MobileFileTypeFilterProps
): JSX.Element => {
  const { onChange, selectedSearchFilterOptions, collapsed, toggleCollapsed } =
    props;
  return (
    <div className="flex flex-col">
      <MobileCollapsibleFilter
        name="File Type"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSelectedOptionsToText(
          getSelectedFileTypes(selectedSearchFilterOptions),
          fileTypes
        )}
      >
        <CheckboxGroup
          allOptions={fileTypes}
          selectedOptions={getSelectedFileTypes(selectedSearchFilterOptions)}
          onChange={onChange}
        />
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileFileTypeFilter;
