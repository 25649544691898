import React from 'react';
import { twMerge } from 'tailwind-merge';

import { getHtmlSafeName } from '../../../../../common/utils';
import { MenuInputGroupProps } from './MenuInterfaces';

interface RadioGroupProps extends MenuInputGroupProps {
  checkedOption: string;
  onChange: (string, any) => void;
  customClassName?: string;
  inputClassName?: string;
}

const RadioGroup = ({
  groupName,
  allOptions,
  checkedOption,
  onChange,
  labelClassName,
  customClassName,
  inputClassName,
}: RadioGroupProps): JSX.Element => (
  <>
    {allOptions.map((option, index) => {
      const { value, label } = option;

      const htmlSafeName = getHtmlSafeName(groupName);
      const htmlSafeId = htmlSafeName + value;

      const isChecked = checkedOption === value;

      return (
        <div
          key={label}
          className={twMerge(
            index !== allOptions.length - 1 && 'md:mb-3 mb-7',
            customClassName,
            'flex md:mb-2 mb-6'
          )}
        >
          <input
            type="radio"
            id={htmlSafeId}
            className={twMerge(
              'cursor-pointer option-box-input',
              isChecked && '-checkedOption',
              inputClassName
            )}
            name={htmlSafeName}
            checked={isChecked}
            onChange={() => {
              onChange(groupName, value);
            }}
          />
          <label
            className={labelClassName ? labelClassName : null}
            htmlFor={htmlSafeId}
          >
            {label}
          </label>
        </div>
      );
    })}
  </>
);

export default RadioGroup;
