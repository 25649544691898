import React from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import Close from '@videoblocks/react-icons/Close';

import { logClickEvent } from '../../containers/appliedFilters/AppliedFiltersUtils';
import { selectSearchFeatures } from '../../selectors/searchSelectors';

interface Props {
  label: string;
  onClear: () => void;
  secondaryClassName?: string;
  className?: string;
  backgroundClassName?: string;
  closeButtonClassName?: string;
}

const AppliedFilter = ({
  label,
  onClear,
  secondaryClassName,
  className = '',
  backgroundClassName = '',
  closeButtonClassName = '',
}: Props) => {
  const features = useSelector(selectSearchFeatures);
  const secondaryClass = `applied-filter-${secondaryClassName || label}`;

  return (
    <div
      className={twMerge(
        `applied-filter ${secondaryClass} ${className}`,
        features.useRemainingSearchUI ? 'mr-2 mb-2' : ''
      )}
      key={label}
    >
      <span
        className={twMerge(
          `${
            features.useRemainingSearchUI
              ? 'bg-blue-100 rounded-bl-lg rounded-tl-lg leading-4 text-blue-2000 font-semibold text-xs py-1.5 pl-2 pr-1'
              : ''
          }`,
          backgroundClassName
        )}
      >
        {label}
      </span>
      <div
        tabIndex={0}
        aria-label={`Remove ${label} filter`}
        role="button"
        className={twMerge(
          `close-button ${
            features.useRemainingSearchUI
              ? 'bg-blue-100 rounded-br-lg rounded-tr-lg leading-4 py-1.5'
              : ''
          }`,
          closeButtonClassName
        )}
        onKeyPress={() => {
          onClear();
          logClickEvent();
        }}
        onClick={() => {
          onClear();
          logClickEvent();
        }}
      >
        <Close
          className="w-2 h-full"
          style={{ stroke: 'black', strokeWidth: 1 }}
        />
      </div>
    </div>
  );
};

export default AppliedFilter;
