import React from 'react';

import {
  SelectedSearchFilterOptions,
  VideoSelectedSearchFilterOptions,
} from '../../../containers/MenuContainerInterfaces';
import CheckboxGroup from '../../menu/common/CheckboxGroup';
import { FilterOption } from '../../menu/common/MenuInterfaces';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const getSelectedMediaDetails = (
  selectedSearchFilterOptions: SelectedSearchFilterOptions
): string[] => {
  const selectedMediaDetails = [];

  if (selectedSearchFilterOptions.propertyReleased) {
    selectedMediaDetails.push('propertyReleased');
  }

  if (selectedSearchFilterOptions.talentReleased) {
    selectedMediaDetails.push('talentReleased');
  }

  return selectedMediaDetails;
};

const convertSelectedOptionsToText = (checkedValues, options) => {
  const selectedLabels = options
    .filter((option) => checkedValues.includes(option.value))
    .map((option) => {
      return (
        <React.Fragment key={option.value}>
          {option.label}
          <br />
        </React.Fragment>
      );
    });

  return selectedLabels;
};

interface MobileMediaDetailsFilterProps {
  onChange: (name: string, value: string | number) => void;
  selectedSearchFilterOptions: VideoSelectedSearchFilterOptions;
  mediaDetailsOptions: FilterOption[];
  collapsed: boolean;
  toggleCollapsed: () => void;
}

const MobileMediaDetailsFilter = (
  props: MobileMediaDetailsFilterProps
): JSX.Element => {
  const {
    onChange,
    selectedSearchFilterOptions,
    mediaDetailsOptions,
    collapsed,
    toggleCollapsed,
  } = props;

  return (
    <div className="flex flex-col">
      <MobileCollapsibleFilter
        name="Media Details"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSelectedOptionsToText(
          getSelectedMediaDetails(selectedSearchFilterOptions),
          mediaDetailsOptions
        )}
      >
        <CheckboxGroup
          allOptions={mediaDetailsOptions}
          selectedOptions={getSelectedMediaDetails(selectedSearchFilterOptions)}
          onChange={onChange}
        />
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileMediaDetailsFilter;
