import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SiteConstants from '../../../../common/SiteConstants/SiteConstants';
import { usePrevious } from '../../../../common/hooks/usePrevious';
import { isEqual } from '../../../../common/utils';
import { searchOrigins } from '../../../Shared/constants';
import {
  toggleMobileFilterModalOpen,
  updateSearchOptionsAndRefresh,
  updateTotalResultsCountForFilter,
} from '../../actions/SearchActions';
import {
  SelectedSearchFilterOptions,
  VideoQualityType,
  VideoSelectedSearchFilterOptions,
} from '../../containers/MenuContainerInterfaces';
import SearchFilterOptions, {
  ALL_VIDEOS_CONTENT_TYPE,
} from '../../entities/SearchFilterOptions';
import SearchOptions from '../../entities/SearchOptions';
import {
  categoryTypes,
  getCategoriesForContentType,
  mediaDetails as VideoMediaDetailsOptions,
  templateTypes,
} from '../../entities/VideoSearchFilterOptions';
import {
  selectSearchFilterOptions,
  selectSearchResultsTotalCountsForFilters,
} from '../../selectors/searchSelectors';
import {
  FilterInputType,
  VideoFilterGroupNames,
} from '../menu/common/MenuEnums';
import { FilterOption } from '../menu/common/MenuInterfaces';
import {
  TEMPLATE_CONTENT_TYPE,
  VideoFiltersByContentType,
  VideoFiltersOptions,
} from './common/FilterUtils';
import MobileFiltersContainer from './common/MobileFiltersContainer';
import MobileCategoriesFilter from './filters/MobileCategoriesFilter';
import MobileDurationFilter from './filters/MobileDurationFilter';
import MobileFrameRatesFilter from './filters/MobileFrameRatesFilter';
import MobileMediaDetailsFilter from './filters/MobileMediaDetailsFilter';
import MobileMediaTypeFilter from './filters/MobileMediaTypeFilter';
import MobileResolutionFilter from './filters/MobileResolutionFilter';
import MobileTemplatesFilter from './filters/MobileTemplatesFilter';

const getCurrentCategoryValue = (selectedCategories) => {
  let selectedCategoriesArray;
  if (!isNaN(selectedCategories)) {
    selectedCategoriesArray = [selectedCategories.toString()];
  } else if (selectedCategories === '') {
    selectedCategoriesArray = [];
  } else {
    selectedCategoriesArray = selectedCategories.split(',');
  }
  let actualCategory;
  selectedCategoriesArray.forEach((selectedCategory) => {
    if (actualCategory === undefined) {
      actualCategory = categoryTypes.find((category) => {
        return category.value === selectedCategory;
      });
    }
  });
  return actualCategory ? actualCategory.value : '';
};

const getCurrentTemplateValue = (selectedCategories) => {
  let selectedCategoriesArray;
  if (!isNaN(selectedCategories)) {
    selectedCategoriesArray = [selectedCategories.toString()];
  } else if (selectedCategories === '') {
    selectedCategoriesArray = [];
  } else {
    selectedCategoriesArray = selectedCategories.split(',');
  }

  let actualTemplate;
  selectedCategoriesArray.forEach((selectedCategory) => {
    if (actualTemplate === undefined) {
      actualTemplate = templateTypes.find((templateCategory) => {
        return templateCategory.value === selectedCategory;
      });
    }
  });

  return actualTemplate ? actualTemplate.value : '';
};

const processSelectedFiltersOptionUpdateFields = (updateFields) => {
  const processedUpdateFields = { ...updateFields };
  if (
    processedUpdateFields.categories &&
    isNaN(Number(processedUpdateFields.categories))
  ) {
    let categories = processedUpdateFields.categories;

    if (categories.indexOf(',') > 0) {
      categories = categories.split(',');
    }
    const selectedCategory = categoryTypes.find((category: FilterOption) => {
      return categories.includes(category.value);
    });
    processedUpdateFields.categories = selectedCategory.value;
  }

  if (processedUpdateFields.templateType) {
    let templateId;
    if (isNaN(Number(processedUpdateFields.templateType))) {
      const selectedCategory = templateTypes.find((category: FilterOption) => {
        return category.value === processedUpdateFields.templateType;
      });

      templateId = String(selectedCategory.categoryId);
    } else {
      templateId = updateFields.templateType;
    }

    processedUpdateFields.categories = processedUpdateFields.categories
      ? `${processedUpdateFields.categories},${templateId}`
      : templateId;
  }

  processedUpdateFields.contentClass =
    SearchFilterOptions.getContentClassFromContentType(
      processedUpdateFields.contentType
    );

  return processedUpdateFields;
};

interface MobileVideoFiltersProps {
  setAppliedFiltersTotalCount: (newAppliedFiltersCount: number) => void;
}

const MobileVideoFilters = ({
  setAppliedFiltersTotalCount,
}: MobileVideoFiltersProps): JSX.Element => {
  const searchResultsTotalCountsForFilters = useSelector(
    selectSearchResultsTotalCountsForFilters
  );
  const selectedSearchFilterOptionsRedux = useSelector(
    selectSearchFilterOptions
  );

  // need to have a local filer states to keep tracks for filters change before updates to global filter
  // state because results won't be show until user press show results
  // and exiting the mobile filter without pressing show results button will reset to the prior filters state
  const [
    selectedSearchFilterOptionsLocal,
    setSelectedSearchFilterOptionsLocal,
  ] = useState<VideoSelectedSearchFilterOptions>({
    ...selectedSearchFilterOptionsRedux,
  });

  const [collapsed, setCollapsed] = useState({
    [VideoFiltersOptions.CONTENT_TYPE]: false,
    [VideoFiltersOptions.VIDEO_QUALITY]: false,
    [VideoFiltersOptions.DURATION]: false,
    [VideoFiltersOptions.MEDIA_DETAILS]: false,
    [VideoFiltersOptions.CATEGORIES]: false,
    [VideoFiltersOptions.FRAME_RATES]: false,
  });

  const toggleCollapsed = (name) => {
    setCollapsed({
      ...collapsed,
      [name]: !collapsed[name],
    });
  };

  const previousSelectedFilterOptionsLocal = usePrevious(
    selectedSearchFilterOptionsLocal
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedSearchFilterOptionsRedux) {
      const { categories: currentCategories } =
        selectedSearchFilterOptionsLocal;
      const templateType = getCurrentTemplateValue(currentCategories);
      const categories = getCurrentCategoryValue(currentCategories);

      setSelectedSearchFilterOptionsLocal({
        ...selectedSearchFilterOptionsLocal,
        categories,
        templateType,
      });
      dispatch(
        updateTotalResultsCountForFilter(selectedSearchFilterOptionsRedux)
      );
    }
  }, [selectedSearchFilterOptionsRedux]);

  // debouncing calls to the search service to fetch total results count
  // so rapid changes to the filters won't trigger multiple api calls
  // necessary for sliders like filters, use for checkbox like filters
  const debouncedUpdateTotalResultsCountForFilter = useCallback(
    debounce((newSearchOptions: SelectedSearchFilterOptions) => {
      dispatch(updateTotalResultsCountForFilter(newSearchOptions));
    }, 100),
    [dispatch]
  );

  // monitoring selectedSearchFilterOptionsLocal and update TotalResultsCount
  // when selectedSearchFilterOptionsLocal changes
  useEffect(() => {
    if (
      previousSelectedFilterOptionsLocal &&
      selectedSearchFilterOptionsLocal &&
      previousSelectedFilterOptionsLocal !== selectedSearchFilterOptionsLocal
    ) {
      const updateFields = {
        ...selectedSearchFilterOptionsLocal,
        page: 1, // Go back to page 1 when any filter is changed
        isPagination: false,
        searchOrigin: searchOrigins.FILTERS,
      };
      // post process updateObj to handle edge cases and conflicts
      const newUpdateFields =
        processSelectedFiltersOptionUpdateFields(updateFields);
      // selectedSearchFilterOptionsRedux.update just returns a new search option object,
      // and doesn't affect the original one, so it won't cause an infinite loop
      const newSearchOptions =
        selectedSearchFilterOptionsRedux.update(newUpdateFields);
      // update update total result count to display within the mobile filter
      // doesn't change filters global redux state yet
      debouncedUpdateTotalResultsCountForFilter(newSearchOptions);
    }
  }, [previousSelectedFilterOptionsLocal, selectedSearchFilterOptionsLocal]);

  useEffect(() => {
    if (selectedSearchFilterOptionsLocal) {
      const defaultSelectedSearchOptions =
        SearchOptions.getDefaultSearchOptions();
      const { contentType } = selectedSearchFilterOptionsLocal;
      const videoFilters = VideoFiltersByContentType[contentType];
      const numberOfAppliedFilters = Object.values(videoFilters).reduce<number>(
        (acc: number, key: string) => {
          if (key === VideoFiltersOptions.DURATION) {
            const { minDuration, maxDuration } =
              selectedSearchFilterOptionsLocal;
            if (
              (minDuration && minDuration > 0) ||
              (maxDuration &&
                maxDuration < SiteConstants.getInstance().getMaxDuration())
            ) {
              return acc + 1;
            }
          } else if (
            key === VideoFiltersOptions.PROPERTY_RELEASED ||
            key === VideoFiltersOptions.TALENT_RELEASED
          ) {
            if (selectedSearchFilterOptionsLocal[key]) {
              return acc + 1;
            }
          } else if (
            !isEqual(
              selectedSearchFilterOptionsLocal[key],
              defaultSelectedSearchOptions[key]
            )
          ) {
            return acc + 1;
          }

          return acc;
        },
        0
      );
      setAppliedFiltersTotalCount(numberOfAppliedFilters);
    }
  }, [selectedSearchFilterOptionsLocal]);

  const handleCheckboxAndRadioFiltersChange = (name, value) => {
    setSelectedSearchFilterOptionsLocal({
      ...selectedSearchFilterOptionsLocal,
      [name]: value,
    });
  };

  const handleMediaTypeChange = (name, value) => {
    if (value === TEMPLATE_CONTENT_TYPE) {
      const newState = {
        ...selectedSearchFilterOptionsLocal,
        videoQuality: VideoQualityType.HD,
        [name]: value,
      };
      setSelectedSearchFilterOptionsLocal(newState);
    } else {
      setSelectedSearchFilterOptionsLocal({
        ...selectedSearchFilterOptionsLocal,
        [VideoFiltersOptions.FRAME_RATES]: [],
        [VideoFiltersOptions.CATEGORIES]: '',
        [VideoFiltersOptions.TEMPLATE_TYPE]: '',
        [VideoFiltersOptions.MIN_DURATION]: '',
        [VideoFiltersOptions.MAX_DURATION]: '',
        [name]: value,
      });
    }
    if (value !== ALL_VIDEOS_CONTENT_TYPE) {
      setCollapsed({
        ...collapsed,
        categories: false,
      });
    }
  };

  const handleDurationSliderAfterChange = (slider1, slider2) => {
    setSelectedSearchFilterOptionsLocal({
      ...selectedSearchFilterOptionsLocal,
      [VideoFiltersOptions.MIN_DURATION]: slider1,
      [VideoFiltersOptions.MAX_DURATION]: slider2,
    });
  };

  const invalidSelectionMessage =
    selectedSearchFilterOptionsLocal.contentType === ALL_VIDEOS_CONTENT_TYPE &&
    'Select Media Type to see Categories';

  const allCategoriesOption = getCategoriesForContentType(
    selectedSearchFilterOptionsLocal.contentType
  );

  const checkedCategoryOption = getCurrentCategoryValue(
    selectedSearchFilterOptionsLocal.categories
  );

  const checkedTemplateOption = getCurrentTemplateValue(
    selectedSearchFilterOptionsLocal.templateType
  );

  const handleClearFilters = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    let newSearchOptions = SearchOptions.getDefaultSearchOptions();

    newSearchOptions = newSearchOptions.update({
      searchTerm: selectedSearchFilterOptionsRedux.searchTerm,
    });

    setSelectedSearchFilterOptionsLocal({
      ...newSearchOptions,
    });

    debouncedUpdateTotalResultsCountForFilter(newSearchOptions);
  };

  const handleShowResult = () => {
    const updateFields = {
      ...selectedSearchFilterOptionsLocal,
      page: 1, // Go back to page 1 when any filter is changed
      isPagination: false,
      searchOrigin: searchOrigins.FILTERS,
    };
    const newUpdateFields =
      processSelectedFiltersOptionUpdateFields(updateFields);
    const newSearchOptions =
      selectedSearchFilterOptionsRedux.update(newUpdateFields);
    dispatch(updateSearchOptionsAndRefresh(newSearchOptions));
    dispatch(toggleMobileFilterModalOpen(false));
  };

  return (
    <MobileFiltersContainer
      resultsTotalCount={searchResultsTotalCountsForFilters}
      handleClearAll={handleClearFilters}
      handleShowResult={handleShowResult}
    >
      <div className="flex flex-col">
        <MobileMediaTypeFilter
          onChange={handleMediaTypeChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[VideoFiltersOptions.CONTENT_TYPE]}
          toggleCollapsed={() =>
            toggleCollapsed(VideoFiltersOptions.CONTENT_TYPE)
          }
        />
        {selectedSearchFilterOptionsLocal.contentType === 'templates' ? (
          <MobileTemplatesFilter
            onChange={handleCheckboxAndRadioFiltersChange}
            allOptions={templateTypes}
            checkedOption={checkedTemplateOption}
            invalidSelectionMessage={invalidSelectionMessage}
            collapsed={collapsed[VideoFiltersOptions.TEMPLATE_TYPE]}
            toggleCollapsed={() =>
              toggleCollapsed(VideoFiltersOptions.TEMPLATE_TYPE)
            }
            inputType={FilterInputType.RADIO}
            groupName={VideoFilterGroupNames.TEMPLATES}
          />
        ) : null}
        <MobileResolutionFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[VideoFiltersOptions.VIDEO_QUALITY]}
          toggleCollapsed={() =>
            toggleCollapsed(VideoFiltersOptions.VIDEO_QUALITY)
          }
        />
        <MobileFrameRatesFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[VideoFiltersOptions.FRAME_RATES]}
          toggleCollapsed={() =>
            toggleCollapsed(VideoFiltersOptions.FRAME_RATES)
          }
        />
        <MobileDurationFilter
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          onAfterChange={handleDurationSliderAfterChange}
          collapsed={collapsed[VideoFiltersOptions.DURATION]}
          toggleCollapsed={() => toggleCollapsed(VideoFiltersOptions.DURATION)}
        />
        <MobileMediaDetailsFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          mediaDetailsOptions={VideoMediaDetailsOptions}
          selectedSearchFilterOptions={selectedSearchFilterOptionsLocal}
          collapsed={collapsed[VideoFiltersOptions.MEDIA_DETAILS]}
          toggleCollapsed={() =>
            toggleCollapsed(VideoFiltersOptions.MEDIA_DETAILS)
          }
        />
        <MobileCategoriesFilter
          onChange={handleCheckboxAndRadioFiltersChange}
          allOptions={allCategoriesOption}
          checkedOption={checkedCategoryOption}
          invalidSelectionMessage={invalidSelectionMessage}
          collapsed={collapsed[VideoFiltersOptions.CATEGORIES]}
          toggleCollapsed={() =>
            toggleCollapsed(VideoFiltersOptions.CATEGORIES)
          }
          inputType={FilterInputType.RADIO}
          groupName={VideoFilterGroupNames.CATEGORIES}
        />
      </div>
    </MobileFiltersContainer>
  );
};

export default MobileVideoFilters;
