import React from 'react';
import { ChromePicker } from 'react-color';
import { twMerge } from 'tailwind-merge';

import './../../filters/ColorFilter.less';

const ensureColorWithoutLeader = (color: string): string => {
  return color.indexOf('#') !== -1 ? color.substring(1) : color;
};

const handleColorChanged = (
  color: string,
  onChange: (string) => void,
  e: React.MouseEvent<HTMLAnchorElement>
): void => {
  e.preventDefault();

  const value = color ? ensureColorWithoutLeader(color) : '';

  // if someone moves the slider without the color being set, we don't want to fire a search
  if (color === '' && value === '000000') {
    return;
  }

  const colorIsEmptyOrComplete = value.length === 6 || value.length === 0;
  if (colorIsEmptyOrComplete) {
    onChange(value);
  }
};

interface ColorPickerProps {
  color?: string;
  onChange: (string) => void;
}

export const ColorPicker = (props: ColorPickerProps): JSX.Element => {
  const { color, onChange } = props;

  return (
    <div className="color-filter-wrapper extended">
      <ChromePicker
        color={color}
        onChangeComplete={(color, e) =>
          handleColorChanged(color.hex, onChange, e)
        }
        disableAlpha={true}
      />
      <div className={twMerge(color.length <= 0 && 'hide')}>
        <a
          key="clear-color-filter"
          className="clear-color-filter"
          href="#"
          onClick={(e) => handleColorChanged('', onChange, e)}
        >
          Reset Color
        </a>
      </div>
    </div>
  );
};
