import React, { Component } from 'react';
import { twMerge } from 'tailwind-merge';

import Collapse from '../../../../common/components/Collapse/Collapse';
import ArrowIcon from './ArrowIcon';

import './CollapsibleCheckboxFilter.less';

interface Props {
  options: Option[];
  handleFilterChanged: (optionName, checked) => void;
  showOptionsOnPageLoad?: boolean;
  groupName?: string;
  name: string;
  checkedValues: number[];
  invalidSelectionMessage: string;
  isCollapsed?: boolean;
  toggleCollapsed: () => void;
  groupId?: string;
}

type Option = Partial<{
  value: string;
  label: string;
  name: string;
  categoryId: number;
}>;

export default class CollapsibleCheckboxFilter extends Component<Props> {
  static defaultProps = {
    checkedValues: [],
    invalidSelectionMessage: null,
  };

  render() {
    const {
      groupId,
      options,
      name,
      groupName,
      checkedValues,
      isCollapsed,
      toggleCollapsed,
    } = this.props;

    const ariaControlId = `filter-control-${groupId || groupName}`;

    return (
      <div
        id={`filter-group-${groupId || groupName}`}
        className={
          isCollapsed || this.props.invalidSelectionMessage
            ? 'collapsed'
            : 'expanded'
        }
      >
        <h3
          className={this.getHeadingClassName()}
          onClick={toggleCollapsed}
          onKeyDown={toggleCollapsed}
          aria-controls={ariaControlId}
        >
          {name}
          <ArrowIcon
            isCollapsed={isCollapsed}
            invalidSelectionMessage={this.props.invalidSelectionMessage}
          />
        </h3>

        {this.renderInvalidSelectionMessage() || (
          <>
            <Collapse isCollapsed={isCollapsed} controlId={ariaControlId}>
              <div className="collapsible-checkbox-filter-wrapper">
                {options.map((option) => {
                  const isChecked = checkedValues.includes(
                    this.sanitizeOptionValue(option.value)
                  );
                  const htmlSafeName = this.getHtmlSafeName(
                    `${name}-${option.value}`
                  );
                  return (
                    <div className="checkbox-spacing" key={option.value}>
                      <input
                        type="checkbox"
                        id={htmlSafeName}
                        name={option.name || name}
                        checked={isChecked}
                        onChange={(e) =>
                          this.handleCheckboxChanged(
                            this.sanitizeOptionValue(option.value),
                            e.currentTarget.checked
                          )
                        }
                      />
                      <label
                        className={this.getCheckboxLabelClassName(isChecked)}
                        htmlFor={htmlSafeName}
                      >
                        <span>{option.label}</span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </Collapse>

            {isCollapsed && (
              <div className="collapsible-filter-selected-option">
                {options
                  .filter((option) =>
                    checkedValues.includes(
                      this.sanitizeOptionValue(option.value)
                    )
                  )
                  .map((option) => {
                    return (
                      <React.Fragment key={option.value}>
                        {option.label}
                        <br />
                      </React.Fragment>
                    );
                  })}
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  getHeadingClassName() {
    return twMerge(
      'heading',
      this.props.invalidSelectionMessage !== null && 'invalid'
    );
  }

  getCheckboxLabelClassName(isChecked) {
    return twMerge('checkbox-label', isChecked && 'selected');
  }

  renderInvalidSelectionMessage() {
    if (!this.props.invalidSelectionMessage) {
      return null;
    }

    return (
      <span className="invalid-message">
        {this.props.invalidSelectionMessage}
      </span>
    );
  }

  sanitizeOptionValue(value) {
    return Number.isInteger(parseInt(value)) ? parseInt(value) : value;
  }

  handleCheckboxChanged = (optionName, checked) => {
    const { handleFilterChanged, groupName } = this.props;

    if (groupName) {
      this.handleGroupFilterUpdate(optionName, checked);
    } else {
      handleFilterChanged(optionName, checked);
    }
  };

  handleGroupFilterUpdate(optionName, checked) {
    const { checkedValues, handleFilterChanged, groupName } = this.props;

    // clone checkedValues
    let selectedValues = checkedValues.slice(0);

    if (checked) {
      selectedValues.push(optionName);
    } else {
      selectedValues = selectedValues.filter(
        (selectedValue) => selectedValue !== optionName
      );
    }

    handleFilterChanged(groupName, selectedValues);
  }

  getHtmlSafeName(rawName) {
    return rawName.replace(/[^\w-]/g, '');
  }
}
