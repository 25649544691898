import React from 'react';

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@videoblocks/react-icons';

interface Props {
  isOpen: boolean;
  closeSideMenu: () => void;
  showSideMenu: () => void;
}

const ToggleMenuButton = ({ isOpen, closeSideMenu, showSideMenu }: Props) => (
  <div className="toggleContainer">
    <div className="toggleContainer-text">Close</div>
    <button
      className="toggleContainer-menuButton"
      aria-label={isOpen ? 'Close Side Menu' : 'Show side menu'}
      onClick={isOpen ? closeSideMenu : showSideMenu}
    >
      {isOpen ? (
        <ChevronLeftIcon className="w-3 h-3 mx-1.5 mt-4" />
      ) : (
        <ChevronRightIcon className="w-3 h-3 mx-1.5 mt-4" />
      )}
    </button>
  </div>
);

export default ToggleMenuButton;
