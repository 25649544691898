import { RelatedSearchCardClickEvent } from '@videoblocks/events-ts/lib/storyblocks/search/RelatedSearchCardClickEvent';

import events from './Events';

export default class RelatedCardClickEventFactory {
  static click(
    searchKeyword: string,
    availableTerms: string[],
    clickedTerm: string,
    clickedTermPosition: number
  ): RelatedSearchCardClickEvent {
    const event = new RelatedSearchCardClickEvent();
    event.meta = events.meta();
    event.searchIdentifiers = events.getSearchIdentifiers();

    event.searchKeyword = searchKeyword;
    event.availableTerms = availableTerms;
    event.clickedTerm = clickedTerm;
    event.clickedTermPosition = clickedTermPosition;
    return event;
  }
}
