import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';

interface HeadProps {
  children: ReactNode;
}

const Head = ({ children }: HeadProps): JSX.Element => (
  <Helmet>{children}</Helmet>
);

export default Head;
