import React from 'react';
import { twMerge } from 'tailwind-merge';

import { contentTypes } from '../../../../../common/Constants';
import { AudioSelectedSearchFilterOptions } from '../../../containers/MenuContainerInterfaces';
import { musicInstruments as instruments } from '../../../entities/AudioSearchFilterOptions';
import CheckboxGroup from '../../menu/common/CheckboxGroup';
import { AudioFilterGroupNames } from '../../menu/common/MenuEnums';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const convertSelectedOptionsToText = (checkedValues, options) => {
  const selectedLabels = options
    .filter((option) => checkedValues.includes(option.value))
    .map((option) => {
      return (
        <React.Fragment key={option.value}>
          {option.label}
          <br />
        </React.Fragment>
      );
    });

  return selectedLabels;
};

interface MobileInstrumentsFilterProps {
  onChange: (name: string, value: string | number) => void;
  selectedSearchFilterOptions: AudioSelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
  className: string;
}

const MobileInstrumentsFilter = (
  props: MobileInstrumentsFilterProps
): JSX.Element => {
  const {
    onChange,
    selectedSearchFilterOptions,
    collapsed,
    toggleCollapsed,
    className,
  } = props;

  const { contentType } = selectedSearchFilterOptions;

  const invalidSelectionMessage =
    contentType !== contentTypes.MUSIC && 'Select Music to see Instruments';

  return (
    <div className={twMerge('flex flex-col', className)}>
      <MobileCollapsibleFilter
        name="Instruments"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSelectedOptionsToText(
          selectedSearchFilterOptions.musicInstruments,
          instruments
        )}
        invalidSelectionMessage={invalidSelectionMessage}
      >
        <CheckboxGroup
          groupName={AudioFilterGroupNames.INSTRUMENTS}
          allOptions={instruments}
          selectedOptions={selectedSearchFilterOptions.musicInstruments}
          onChange={onChange}
        />
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileInstrumentsFilter;
