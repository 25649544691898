import { RelatedSearchCardDisplayEvent } from '@videoblocks/events-ts/lib/storyblocks/search/RelatedSearchCardDisplayEvent';

import events from './Events';

export default class RelatedCardDisplayEventFactory {
  static display(
    searchKeyword: string,
    availableTerms: string[]
  ): RelatedSearchCardDisplayEvent {
    const event = new RelatedSearchCardDisplayEvent();
    event.meta = events.meta();
    event.searchIdentifiers = events.getSearchIdentifiers();

    event.searchKeyword = searchKeyword;
    event.availableTerms = availableTerms;
    return event;
  }
}
