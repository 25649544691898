import React from 'react';
import { twMerge } from 'tailwind-merge';

import { getHtmlSafeName } from '../../../../../common/utils';
import { MenuInputGroupProps } from './MenuInterfaces';
import { sanitizeOptionValue } from './MenuUtils';

const handleGroupFilterUpdate = (
  groupName: string,
  optionName: any,
  checked: boolean,
  selectedOptions: any[],
  onChange: (string, value: any | any[]) => void
): void => {
  let updatedSelectedOptions = [...selectedOptions];

  if (checked) {
    updatedSelectedOptions.push(optionName);
  } else {
    updatedSelectedOptions = updatedSelectedOptions.filter(
      (selectedValue) => selectedValue !== optionName
    );
  }

  onChange(groupName, updatedSelectedOptions);
};

const handleCheckboxChanged = (
  groupName: string,
  optionName: string,
  checked: boolean,
  selectedOptions: any[],
  onChange: (string, value: any | any[]) => void
): void => {
  if (groupName) {
    handleGroupFilterUpdate(
      groupName,
      optionName,
      checked,
      selectedOptions,
      onChange
    );
  } else {
    onChange(optionName, checked);
  }
};

interface CheckboxGroupProps extends MenuInputGroupProps {
  selectedOptions: any[];
  onChange: (string, value: any | any[]) => void;
}

const CheckboxGroup = (props: CheckboxGroupProps): JSX.Element => {
  const { groupName, allOptions, selectedOptions, onChange, labelClassName } =
    props;

  return (
    <>
      {allOptions.map((option, index) => {
        const { value, label } = option;

        const isChecked = selectedOptions.includes(sanitizeOptionValue(value));

        const htmlSafeName = getHtmlSafeName(`${groupName}-${option.value}`);

        const className = twMerge(
          'flex md:mb-2 mb-6',
          index !== allOptions.length - 1 && 'md:mb-3 mb-7'
        );

        return (
          <div key={label} className={className}>
            <input
              className="w-4 h-4 cursor-pointer"
              type="checkbox"
              id={htmlSafeName}
              name={label}
              checked={isChecked}
              onChange={(e) =>
                handleCheckboxChanged(
                  groupName,
                  sanitizeOptionValue(value),
                  e.currentTarget.checked,
                  selectedOptions,
                  onChange
                )
              }
            />
            <label
              className={`text-sm ${labelClassName ? labelClassName : null}`}
              htmlFor={htmlSafeName}
            >
              <span>{option.label}</span>
            </label>
          </div>
        );
      })}
    </>
  );
};

export default CheckboxGroup;
