import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';

import InformationCircle from '@videoblocks/react-icons/InformationCircle';

interface OwnProps {
  tooltip?: string;
}

const FilterOptionTooltip = ({ tooltip }: OwnProps): JSX.Element | null => {
  const [open, setOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: 'right',
      modifiers: [
        { name: 'offset', options: { offset: [0, 14] } },
        { name: 'arrow', options: { element: arrowElement } },
        { name: 'preventOverflow', options: { mainAxis: true, altAxis: true } },
      ],
    }
  );

  // react Portals do not work with SSR, so if this is rendering on the server side we will skip the tooltip.
  const canRender = typeof window !== 'undefined' && !!window.document?.body;

  if (!tooltip) {
    return null;
  }

  return (
    <>
      <InformationCircle
        className="w-3.5 h-3.5 ml-2"
        ref={setReferenceElement}
        onMouseEnter={() => {
          setOpen(true);
          void update();
        }}
        onMouseLeave={() => setOpen(false)}
        color={canRender ? '' : 'currentColor'}
        title={canRender ? '' : tooltip}
      />
      {canRender &&
        createPortal(
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={`z-40 ${open ? '' : 'hidden'}`}
          >
            <div className="text-sm whitespace-normal font-sans leading-5 bg-white rounded shadow-lg p-4 w-52">
              {tooltip}
            </div>
            <div
              ref={setArrowElement}
              className="w-1.5 -left-1.5 overflow-hidden"
              style={styles.arrow}
            >
              <div className="w-2 h-2 bg-white transform rotate-45 translate-x-1/2 shadow-lg" />
            </div>
          </div>,
          document.body
        )}
    </>
  );
};
export default FilterOptionTooltip;
