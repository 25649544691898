import { builder } from '@builder.io/react';
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import ProminentSearch from '@videoblocks/shared-components/dist/mjs/components/Search/ProminentSearch';
import { searchOrigins } from '@videoblocks/shared-components/dist/mjs/components/Shared/constants';
import { SearchFilterContentTypes } from '@videoblocks/shared-components/dist/mjs/components/Shared/enums';

import { audioSetFiltersChanged } from '../../../Audio/actions/AudioActions';
import {
  selectAuthPermissions,
  selectHasAnySubscription,
  selectUseSnapshotsUI,
} from '../../../auth/AuthSelectors';
import deferToNextFrame from '../../Shared/utils/deferToNextFrame';
import {
  clearSuggestionsQuery,
  isSearchAppLoaded,
  setLoadingForSearch,
  updateSearchOptionsAndRefresh,
} from '../actions/SearchActions';
import { ProminentSearchOptionsContext } from '../components/HomepageProminentSearch';
import SearchFilterOptions from '../entities/SearchFilterOptions';
import { templateTypes } from '../entities/VideoSearchFilterOptions';
import {
  selectIsEnterpriseMember,
  selectSearchFilterOptions,
} from '../selectors/searchSelectors';
import {
  encodeSearchTerm,
  fetchTypeahead,
  getDecodedSearchTerm,
} from '../utils/searchUtils';
import {
  AnySearchFilterOptions,
  SelectedSearchFilterOptions,
} from './MenuContainerInterfaces';

interface ProminentSearchProps {
  showDropDown?: boolean;
  showContentToggles?: boolean;
  alignment?: 'left' | 'center';
  useNewSearchUI?: boolean;
}

const constructSearchFilterOptions = (
  contentType: SearchFilterContentTypes,
  searchTerm = '',
  searchOrigin = searchOrigins.SEARCH_BAR
): Partial<SelectedSearchFilterOptions> => {
  const contentClass =
    SearchFilterOptions.getContentClassFromContentType(contentType);
  return {
    contentClass,
    contentType,
    isPagination: false,
    page: 1,
    searchOrigin,
    searchTerm,
    similarTo: null,
    searchSimilarTitle: null,
    templateType: templateTypes
      .find(({ urlId }) => urlId === contentType)
      ?.categoryId?.toString(),
  };
};

function ProminentSearchContainer({
  showDropDown = true,
  showContentToggles = true,
  alignment = 'left',
  useNewSearchUI = false,
}: ProminentSearchProps) {
  const dispatch = useDispatch<any>();

  const prominentSearchOptions = React.useContext(
    ProminentSearchOptionsContext
  );

  const initialSelectedSearchFilterOptions = useSelector(
    selectSearchFilterOptions
  );
  const [selectedSearchFilterOptions, setSelectedSearchFilterOptions] =
    useState<AnySearchFilterOptions>(
      initialSelectedSearchFilterOptions
        ? initialSelectedSearchFilterOptions.update(
            constructSearchFilterOptions(
              prominentSearchOptions?.[0]?.id ||
                initialSelectedSearchFilterOptions.contentType,
              initialSelectedSearchFilterOptions.searchTerm,
              initialSelectedSearchFilterOptions.searchOrigin
            )
          )
        : {}
    );

  const userHasAnySubscription = useSelector(selectHasAnySubscription);
  const isEnterpriseMember = useSelector(selectIsEnterpriseMember);
  const { pproPluginEnabled } = useSelector(selectAuthPermissions);

  const useSnapshotsUI = useSelector(selectUseSnapshotsUI);

  const { contentType = '', searchTerm = '' } = selectedSearchFilterOptions;
  const decodedSearchTerm = getDecodedSearchTerm(searchTerm);

  const handleDropdownChange = useCallback(
    (contentType) => {
      // This fixes https://videoblocks.atlassian.net/browse/LNL-227
      if (showDropDown) return;
      const newSelectedSearchFilterOptions = selectedSearchFilterOptions.update(
        constructSearchFilterOptions(contentType, decodedSearchTerm)
      );
      setSelectedSearchFilterOptions(newSelectedSearchFilterOptions);
    },
    [
      showDropDown,
      selectedSearchFilterOptions,
      decodedSearchTerm,
      constructSearchFilterOptions,
    ]
  );

  const handleSubmit = useCallback(
    (contentType: string, searchTerm: string, searchOrigin: string) => {
      dispatch(clearSuggestionsQuery());
      dispatch(setLoadingForSearch(true));

      // Defer work that might slow down more important UI updates.
      deferToNextFrame(() => {
        dispatch(audioSetFiltersChanged(true));
      });

      deferToNextFrame(() => {
        builder.init('e7fb3d1e4da14573bd2a1edb7bfee5f1');
        builder.track('search');
      });
      deferToNextFrame(() => {
        const encodedSearchTerm = encodeSearchTerm(searchTerm);
        const newSelectedSearchFilterOptions =
          selectedSearchFilterOptions.update(
            constructSearchFilterOptions(
              contentType as SearchFilterContentTypes,
              encodedSearchTerm,
              searchOrigin
            )
          );
        dispatch(updateSearchOptionsAndRefresh(newSelectedSearchFilterOptions));
      });
    },
    [dispatch, selectedSearchFilterOptions, constructSearchFilterOptions]
  );

  return (
    <ProminentSearch
      onDropdownChange={handleDropdownChange}
      onSubmit={handleSubmit}
      searchTerm={decodedSearchTerm}
      userHasAnySubscription={userHasAnySubscription}
      fetchTypeahead={fetchTypeahead}
      isEnterpriseMember={isEnterpriseMember}
      pproPluginEnabled={pproPluginEnabled}
      isSearchAppLoaded={isSearchAppLoaded}
      incrementTelemetry={Telemetry.increment}
      imagesUrl={__ASSETS_COMMON_IMAGES_URL__}
      prominentSearchOptions={prominentSearchOptions}
      // in ProminentSearch, contentTypeFromPrevious sets default contentType (`contentType` prop unused)
      contentTypeFromPrevious={contentType as SearchFilterContentTypes}
      showDropDown={showDropDown}
      showContentToggles={showContentToggles}
      useNewSearchUI={useNewSearchUI}
      useSnapshots={useSnapshotsUI}
      alignment={alignment}
    />
  );
}

export default ProminentSearchContainer;
