import React from 'react';

import { SelectedSearchFilterOptions } from '../../../containers/MenuContainerInterfaces';
import SearchFilterOptions from '../../../entities/SearchFilterOptions';
import { FilterGroupNames } from '../../menu/common/MenuEnums';
import RadioGroup from '../../menu/common/RadioGroup';
import MobileCollapsibleFilter from '../common/MobileCollapsibleFilter';

const convertSelectedOptionsToText = (selectedOption, allOptions) => {
  const selectedLabels = allOptions
    .filter((option) => {
      return option.value === selectedOption;
    })
    .map((option) => {
      return option.label;
    });
  return selectedLabels.length > 0 ? selectedLabels[0] : '';
};

interface MobileMediaTypeFilterProps {
  onChange: (name: string, value: string | number) => void;
  selectedSearchFilterOptions: SelectedSearchFilterOptions;
  collapsed: boolean;
  toggleCollapsed: () => void;
}

const MobileMediaTypeFilter = (
  props: MobileMediaTypeFilterProps
): JSX.Element => {
  const { onChange, selectedSearchFilterOptions, collapsed, toggleCollapsed } =
    props;

  return (
    <div className="flex flex-col">
      <MobileCollapsibleFilter
        name="Media Type"
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        selectedOption={convertSelectedOptionsToText(
          selectedSearchFilterOptions.contentType,
          SearchFilterOptions.getContentTypes()
        )}
      >
        <RadioGroup
          groupName={FilterGroupNames.CONTENT_TYPE}
          allOptions={SearchFilterOptions.getContentTypes()}
          checkedOption={selectedSearchFilterOptions.contentType}
          onChange={onChange}
        />
      </MobileCollapsibleFilter>
      <hr className="divider block" />
    </div>
  );
};

export default MobileMediaTypeFilter;
