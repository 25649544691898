import React, { Component } from 'react';
import { twMerge } from 'tailwind-merge';

import { SearchFilterContentTypes } from '@videoblocks/shared-components';

import Collapse from '../../../../common/components/Collapse/Collapse';
import { FilterCategories } from '../../containers/MenuContainerInterfaces';
import ArrowIcon from './ArrowIcon';
import FilterRadioOption from './FilterRadioOption';

interface Props {
  name: string;
  filterName?: string;
  filterValues?: object[];
  checked?: FilterCategories[] | SearchFilterContentTypes | string;
  showOptionBoxesOnPageLoad?: boolean;
  numberOfChoicesToShowInitially?: number;
  alphabetizeOptions?: boolean;
  invalidSelectionMessage?: string;
  isCollapsed?: boolean;
  toggleCollapsed: (string) => void;
  handleFilterChanged: (filterName: string, filterValue: string) => void;
  hideValue?: boolean;
}

export default class CollapsibleRadioFilter extends Component<Props> {
  static defaultProps = {
    invalidSelectionMessage: null,
  };

  render() {
    const {
      name,
      checked,
      filterValues,
      alphabetizeOptions,
      isCollapsed,
      hideValue,
      toggleCollapsed,
      invalidSelectionMessage,
    } = this.props;

    const htmlSafeName = this.getHtmlSafeName(name);
    const filteredItems = this.getFilteredItems(
      filterValues,
      alphabetizeOptions
    );
    const isClosed = isCollapsed || Boolean(invalidSelectionMessage);

    return (
      <div
        id={`filter-group-${htmlSafeName}`}
        className={isClosed ? 'collapsed' : 'expanded'}
      >
        <h3
          className={this.getHeadingClassName()}
          onClick={toggleCollapsed}
          onKeyDown={toggleCollapsed}
          aria-controls={htmlSafeName}
        >
          {name}
          <ArrowIcon
            isCollapsed={isCollapsed}
            invalidSelectionMessage={this.props.invalidSelectionMessage}
          />
        </h3>

        {this.renderInvalidSelectionMessage() || (
          <>
            <Collapse
              isCollapsed={isClosed}
              controlId={htmlSafeName + '-container'}
            >
              {filteredItems.map((item) => {
                const { value, label, tooltip } = item;
                return (
                  <FilterRadioOption
                    key={value}
                    id={value}
                    label={label}
                    isChecked={checked === value}
                    name={name}
                    onSelectOption={() => this.onSelectOption(value)}
                    tooltip={tooltip}
                  />
                );
              })}
            </Collapse>

            {isCollapsed && !hideValue && (
              <div className="collapsible-filter-selected-option">
                {this.getCheckedLabels(filterValues, checked)}
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  getHeadingClassName() {
    return twMerge(
      'heading',
      this.props.invalidSelectionMessage !== null && 'invalid'
    );
  }

  renderInvalidSelectionMessage() {
    return this.props.invalidSelectionMessage ? (
      <span className="invalid-message">
        {this.props.invalidSelectionMessage}
      </span>
    ) : null;
  }

  getCheckedLabels(items, selectedValue) {
    return items
      .filter((item) => {
        return item.value === selectedValue;
      })
      .map((item) => {
        return item.label;
      });
  }

  onSelectOption(filterValue) {
    this.props.handleFilterChanged(this.props.filterName, filterValue);
  }

  getFilteredItems(items, alphabetize) {
    const clonedItems = items.slice(0);

    if (alphabetize) {
      clonedItems.sort((a, b) => {
        return a.label > b.label ? 1 : -1;
      });
    }

    return clonedItems.splice(0, items.length);
  }

  getHtmlSafeName(rawName) {
    return rawName.replace(/\W/g, '');
  }
}
