import React from 'react';
import { useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';

import { selectDetailsStockItemIsContentClass } from '../../../../Details/selectors/detailsSelectors';
import { ContentClass } from '../../../Shared/enums';
import FilterOptionTooltip from './FilterOptionTooltip';

import './filter-radio-option.less';

interface OwnProps {
  id: string;
  label: string;
  link?: string;
  name: string;
  isChecked: boolean;
  onSelectOption: (id: string) => void;
  tooltip?: string;
  className?: string;
  inputClassName?: string;
}

const FilterRadioOption = ({
  id,
  isChecked,
  label,
  link,
  name,
  onSelectOption,
  tooltip,
  className,
  inputClassName,
}: OwnProps): JSX.Element => {
  const htmlSafeName = name.replace(/\W/g, '');
  const htmlSafeId = htmlSafeName + id;
  const isVideoDetailsPage = useSelector(
    selectDetailsStockItemIsContentClass(ContentClass.Video)
  );
  const isImageDetailsPage = useSelector(
    selectDetailsStockItemIsContentClass(ContentClass.Image)
  );

  return (
    <div
      className={twMerge(
        'option-box flex items-center text-white pt-2.5',
        isVideoDetailsPage && 'videoDetailsPage pl-2.5 p-0',
        isImageDetailsPage && 'imageDetailsPage pl-2.5 p-0',
        className
      )}
    >
      <input
        type="radio"
        id={htmlSafeId}
        className={twMerge(
          `option-box-input${isChecked ? '-checked' : ''}`,
          inputClassName,
          'cursor-pointer'
        )}
        name={htmlSafeName}
        checked={isChecked}
        onChange={() => onSelectOption(id)}
      />
      <label
        htmlFor={htmlSafeId}
        className={twMerge(
          'flex items-center',
          isVideoDetailsPage || isImageDetailsPage ? 'text-white' : 'text-black'
        )}
      >
        {link ? (
          <a
            className="non-linking-a"
            href={link}
            onClick={(e) => {
              e.preventDefault();
              onSelectOption(id);
            }}
          >
            {label}
          </a>
        ) : (
          label
        )}
        {tooltip && <FilterOptionTooltip tooltip={tooltip} />}
      </label>
    </div>
  );
};

export default FilterRadioOption;
