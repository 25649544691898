import React from 'react';

import { Button, ButtonColor } from '@videoblocks/storywind';

const MobileFiltersContainer = (props): JSX.Element => {
  const { children, handleClearAll, handleShowResult, resultsTotalCount } =
    props;

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow-1 flex-shrink-1 h-9/10 overflow-scroll bg-gray-110">
        <div className="mobile-filters-container shadow-none">{children}</div>
      </div>
      <div className="flex w-full bg-white justify-around py-2 h-1/10">
        <Button color={ButtonColor.Light} squared onClick={handleClearAll}>
          <span className="text-blue-1000">Clear all</span>
        </Button>
        <Button
          color={ButtonColor.Secondary}
          squared
          onClick={handleShowResult}
        >
          {resultsTotalCount || resultsTotalCount === 0
            ? `Show ${resultsTotalCount} results`
            : 'Show results'}
        </Button>
      </div>
    </div>
  );
};

export default MobileFiltersContainer;
