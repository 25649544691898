import { Telemetry } from '@videoblocks/kafka-rest-client';

export function logClickEvent() {
  Telemetry.increment('search.applied_filter.clicked');
}

export function getOptionLabel(value, allOptions) {
  const option = allOptions.find(
    (option) => option.categoryId === value || option.value === value
  );
  return option ? option.label : null;
}
